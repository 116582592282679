import React, { useEffect, useState, useMemo } from 'react';
import style from './Analytics.module.scss';
import { AnalyticsItem } from './AnalyticsItem';
import ReactPaginate from 'react-paginate';
import arrowPagination from '../../assets/arrowPagination.svg';
import { useQuery, gql } from '@apollo/client';

export const Analytics: React.FC = () => {
  const [liqPairs, setLiqPairs] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(-1);
  const [tvlSortType, setTvlSortType] = useState<any>(null);
  const [aprSortType, setAprSortType] = useState<any>(null);
  const [volumeSortType, setVolumeSortType] = useState<any>(null);
  const [dataToShow, setDataToShow] = useState<any[]>([]);

  const GQL_QUERY = gql`
    query GetData {
      PairSnapshots {
        volumeUSD
        managedApy
        swapApr
        pair {
          address
          curveId
          tvlUSD
          token0
          token0Symbol
          token1
          token1Symbol
        }
      }
    }
  `;

  const { loading, data } = useQuery(GQL_QUERY);

  const PageSize = 10;

  useEffect(() => {
    if (data) {
      const pairsData: any = [];

      data.PairSnapshots.forEach((pairSnapshot: any) => {
        pairsData.push({
          pair: pairSnapshot.pair,
          address: pairSnapshot.pair.address,
          tvl: pairSnapshot.pair.tvlUSD,
          curveId: pairSnapshot.pair.curveId,
          //@ts-ignore
          apr: Number(pairSnapshot.swapApr) + Number(pairSnapshot.managedApy),
          managedApy: pairSnapshot.managedApy,
          swapApr: pairSnapshot.swapApr,
          volume: pairSnapshot.volumeUSD,
        });
      });

      setLiqPairs(pairsData);
    }
  }, [data]);

  useEffect(() => {
    if (liqPairs.length > 0 && currentPage === -1) {
      setCurrentPage(0);
    }
  }, [liqPairs, setCurrentPage]);

  useEffect(() => {
    let sortedPairs = [...liqPairs];
    if (tvlSortType === 'asc') {
      sortedPairs = sortedPairs.sort((a, b) => a.tvl - b.tvl);
    } else if (tvlSortType === 'desc') {
      sortedPairs = sortedPairs.sort((a, b) => b.tvl - a.tvl);
    }
    if (volumeSortType === 'asc') {
      sortedPairs = sortedPairs.sort((a, b) => a.volume - b.volume);
    } else if (volumeSortType === 'desc') {
      sortedPairs = sortedPairs.sort((a, b) => b.volume - a.volume);
    }
    if (aprSortType === 'asc') {
      sortedPairs = sortedPairs.sort((a, b) => a.apr - b.apr);
    } else if (aprSortType === 'desc') {
      sortedPairs = sortedPairs.sort((a, b) => b.apr - a.apr);
    }
    setDataToShow(sortedPairs);
  }, [tvlSortType, volumeSortType, aprSortType, liqPairs]);

  const handlePageClick = (page: any) => {
    setCurrentPage(page.selected);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = currentPage * PageSize;
    const lastPageIndex = Math.min(
      firstPageIndex + PageSize,
      dataToShow.length
    );
    return dataToShow.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, dataToShow]);

  const sortTVL = () => {
    setCurrentPage(0);
    if (tvlSortType === 'asc') {
      setTvlSortType('desc');
      setAprSortType(null); // Reset APR sort type
      setVolumeSortType(null);
    } else {
      setTvlSortType('asc');
      setAprSortType(null); // Reset APR sort type
      setVolumeSortType(null);
    }
  };

  const sortAPR = () => {
    setCurrentPage(0);
    if (aprSortType === 'asc') {
      setAprSortType('desc');
      setTvlSortType(null); // Reset TVL sort type
      setVolumeSortType(null);
    } else {
      setAprSortType('asc');
      setTvlSortType(null); // Reset TVL sort type
      setVolumeSortType(null);
    }
  };

  const sortVolume = () => {
    setCurrentPage(0);
    if (volumeSortType === 'asc') {
      setVolumeSortType('desc');
      setTvlSortType(null); // Reset TVL sort type
      setAprSortType(null); // Reset APR sort type
    } else {
      setVolumeSortType('asc');
      setTvlSortType(null); // Reset TVL sort type
      setAprSortType(null); // Reset APR sort type
    }
  };

  return (
    <section className={style.analytics}>
      <table>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>Name</th>
            <th style={{ cursor: 'pointer' }} onClick={sortTVL}>
              TVL ($) {tvlSortType === 'asc' ? '△' : '▽'}
            </th>
            <th style={{ cursor: 'pointer' }} onClick={sortVolume}>
              Volume (24h) {volumeSortType === 'asc' ? '△' : '▽'}
            </th>
            <th style={{ cursor: 'pointer' }} onClick={sortAPR}>
              APR (24h) {aprSortType === 'asc' ? '△' : '▽'}
            </th>
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            currentTableData.map((item: any, index: number) => (
              <AnalyticsItem
                item={item.pair}
                curveId={item.curveId}
                key={index}
                address={item.address}
                tvl={item.tvl}
                volume={item.volume}
                swapApr={item.swapApr}
                managedApy={item.managedApy}
                apr={item.apr}
              />
            ))
          ) : (
            <tr className={style.loading}>
              <td colSpan={4}>Loading...</td>
            </tr>
          )}
        </tbody>
      </table>
      {liqPairs.length > PageSize && (
        <ReactPaginate
          className="pagination"
          breakLabel="..."
          nextLabel={
            <img
              src={arrowPagination}
              style={{ transform: 'rotate(180deg)' }}
              alt="Next"
            />
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={Math.ceil(liqPairs.length / PageSize)}
          previousLabel={<img src={arrowPagination} alt="Previous" />}
          renderOnZeroPageCount={null}
          forcePage={currentPage}
        />
      )}
    </section>
  );
};
