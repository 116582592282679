import React, { useState } from 'react';
import style from './Header.module.scss';
import { Network } from './Network';
import { WalletPanel } from './WalletPanel';
import { ChooseTheme } from './ChooseTheme';
import { useNavigate } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

export const Header: React.FC = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const goTo = (path: string) => {
    navigate(path);
  };

  const goToMobile = (path: string) => {
    navigate(path);
    setShowMenu(false);
  };

  const toggleMenu = () => {
    setShowMenu(true);
  };

  const isActiveRoute = (path: string) => {
    return window.location.pathname.includes(path);
  };

  return (
    <header className={style.header}>
      <ul className={style.desktopHeader}>
        <div className={style.header__logo}>
          <svg
            width="26"
            height="36"
            viewBox="0 0 26 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 0.5C13 0.5 16.0491 4.58456 16.0491 6.93587C16.0491 8.61994 14.6839 9.98491 13 9.98491C11.3162 9.98491 9.95097 8.61994 9.95097 6.93587C9.95097 4.58456 13 0.5 13 0.5ZM16.4394 35.5H9.56064L0.716309 12.4679H6.22752C9.36804 12.4679 11.914 15.0138 11.914 18.1543C11.914 22.3492 9.99756 23.4037 9.89266 25.5187C9.79945 27.3987 11.4147 28.6261 13 28.6261C14.5854 28.6261 16.2006 27.3987 16.1074 25.5187C16.0025 23.4037 14.0861 22.3492 14.0861 18.1543C14.0861 15.0138 16.632 12.4679 19.7725 12.4679H25.2837L16.4394 35.5Z"
              fill="#0F3846"
            />
          </svg>
        </div>
        <li
          className={isActiveRoute('/swap') ? style.active_route : ''}
          onClick={() => goTo('/swap')}
        >
          Swap
        </li>
        <li
          className={isActiveRoute('/add_liquidity') ? style.active_route : ''}
          onClick={() => goTo('/add_liquidity')}
        >
          Add Liquidity
        </li>
        <li
          className={
            isActiveRoute('/remove_liquidity') ? style.active_route : ''
          }
          onClick={() => goTo('/remove_liquidity')}
        >
          Remove Liquidity
        </li>
        <li
          className={isActiveRoute('/analytics') ? style.active_route : ''}
          onClick={() => goTo('/analytics')}
        >
          Analytics
        </li>
      </ul>
      <div className={`${style.header__container} ${style.desktopHeader}`}>
        <ChooseTheme />
        <Network />
        <WalletPanel />
      </div>

      <div className={style.mobileHeader}>
        <svg
          width="26"
          height="36"
          viewBox="0 0 26 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 0.5C13 0.5 16.0491 4.58456 16.0491 6.93587C16.0491 8.61994 14.6839 9.98491 13 9.98491C11.3162 9.98491 9.95097 8.61994 9.95097 6.93587C9.95097 4.58456 13 0.5 13 0.5ZM16.4394 35.5H9.56064L0.716309 12.4679H6.22752C9.36804 12.4679 11.914 15.0138 11.914 18.1543C11.914 22.3492 9.99756 23.4037 9.89266 25.5187C9.79945 27.3987 11.4147 28.6261 13 28.6261C14.5854 28.6261 16.2006 27.3987 16.1074 25.5187C16.0025 23.4037 14.0861 22.3492 14.0861 18.1543C14.0861 15.0138 16.632 12.4679 19.7725 12.4679H25.2837L16.4394 35.5Z"
            fill="#0F3846"
          />
        </svg>
        <div>
          <div className={style.mobileHeader__wrapper}>
            <WalletPanel />
            <div
              className={style.mobileHeader__burger}
              onClick={() => toggleMenu()}
            >
              <div
                className={`${style.center} ${
                  showMenu ? style.centerOpened : ''
                }`}
              >
                <div></div>
              </div>
            </div>
          </div>
          <div style={{ display: showMenu ? 'flex' : 'none' }}>
            <OutsideClickHandler
              onOutsideClick={() => {
                setTimeout(() => {
                  if (showMenu) {
                    setShowMenu(false);
                  }
                }, 10);
              }}
            >
              <ul className={`${style.mobileMenu}`}>
                <li>
                  <ChooseTheme />
                </li>
                <li
                  className={isActiveRoute('/swap') ? style.active_route : ''}
                  onClick={() => goToMobile('/swap')}
                >
                  Swap
                </li>
                <li
                  className={
                    isActiveRoute('/add_liquidity') ? style.active_route : ''
                  }
                  onClick={() => goToMobile('/add_liquidity')}
                >
                  Add Liquidity
                </li>
                <li
                  className={
                    isActiveRoute('/remove_liquidity') ? style.active_route : ''
                  }
                  onClick={() => goToMobile('/remove_liquidity')}
                >
                  Remove Liquidity
                </li>
                <li
                  className={
                    isActiveRoute('/analytics') ? style.active_route : ''
                  }
                  onClick={() => goToMobile('/analytics')}
                >
                  Analytics
                </li>
              </ul>
            </OutsideClickHandler>
          </div>
        </div>
      </div>
    </header>
  );
};
