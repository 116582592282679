import React, { useState, useEffect, useRef } from 'react';
import style from './AddLiquidity.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState, AppDispatch } from '../../../redux/store';
import CurrencyInput from 'react-currency-input-field';
import type { Token } from '../../../interfaces';
import { ConnectButton } from '../../../rainbow';
import { LiquidityType, TransactionStatus } from '../../../enums';
import { CSSTransition } from 'react-transition-group';
import { getTokens } from '../../../redux/actions/tokens';
import { TokenIcon } from '../../TokenIcon';
import OutsideClickHandler from 'react-outside-click-handler';

import {
  ROUTER_ADDRESS,
  SLIPPAGE,
  APPROVE_AMOUNT,
  UNKNOWN_ICON,
  toAddress as convertAddress,
} from '../../../constants';
import {
  erc20ABI,
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useSendTransaction,
  usePrepareSendTransaction,
  useProvider,
  Address,
  useToken,
  useNetwork,
} from 'wagmi';
import { Ether, CurrencyAmount, Percent } from '@reservoir-labs/sdk-core';
import {
  Fetcher,
  Pair,
  Router,
  MethodParameters,
  FEE_ACCURACY,
} from '@reservoir-labs/sdk';
import { parseUnits } from '@ethersproject/units';
import { AddressZero } from '@ethersproject/constants';
import emitter from '../../../events';
import { Balance } from '../../Balance';
import { TokenBalance } from '../../Wallet/TokenBalance';
import { Price } from '../../Price';

const toAddress = (value: string): Address => {
  return `0x${value.replace('0x', '')}`;
};

enum TokenListType {
  from = 'from',
  to = 'to',
}

interface AddLiquidityProps {
  liquidityType: LiquidityType;
  handleTriggerChangeCurveId: (curveId: any) => void;
}

export const AddLiquidity: React.FC<AddLiquidityProps> = ({
  liquidityType,
  handleTriggerChangeCurveId,
}) => {
  const [tokenListIsOpened, setTokenListIsOpened] = useState<boolean>(false);
  const [tokensToShow, setTokensToShow] = useState<Token[]>([]);
  const [tokenSearchQuery, setTokenSearchQuery] = useState<string>('');
  const [tokenToSearch, setTokenToSearch] = useState<string | undefined>(
    undefined
  );
  const { address } = useAccount();
  const [tokenAAmount, setTokenAAmount] = useState('0');
  const [tokenBAmount, setTokenBAmount] = useState('0');
  const [isPairExist, setIsPairExist] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [balanceA, setBalanceA] = useState(0);
  const [balanceB, setBalanceB] = useState(0);
  const { chain: chain_id } = useNetwork();

  const handleBalanceA = (balance: any) => {
    setBalanceA(balance);
  };

  const handleBalanceB = (balance: any) => {
    setBalanceB(balance);
  };

  const currentTxHash = useSelector(
    (state: RootState) => state.transactionsReducer.current_transaction
  );

  const modalRef = useRef(null);

  const [tokenAData, setTokenAData] = useState<Token | null>(null);
  const [tokenBData, setTokenBData] = useState<Token | null>(null);

  const [tokenA, setTokenA] = useState<any>(null);
  const [tokenB, setTokenB] = useState<any>(null);

  const [tokenAQuery, setTokenAQuery] = useState<any>(null);
  const [tokenBQuery, setTokenBQuery] = useState<any>(null);

  const tokens = useSelector((state: RootState) => state.tokensReducer.tokens);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenAQueryParam = params.get('tokenA');
    const tokenBQueryParam = params.get('tokenB');
    const curveIdParam = params.get('curveId');

    if (tokenAQueryParam && tokenBQueryParam && curveIdParam && tokens.length) {
      const tokenAResult = tokens.find(
        (item: any) =>
          item.address.toLowerCase() === tokenAQueryParam.toLocaleLowerCase()
      );
      const tokenBResult = tokens.find(
        (item: any) =>
          item.address.toLowerCase() === tokenBQueryParam.toLocaleLowerCase()
      );

      if (tokenAResult !== undefined) {
        setTokenAData(tokenAResult);
      } else {
        setTokenAQuery(tokenAQueryParam);
      }

      if (tokenBResult !== undefined) {
        setTokenBData(tokenBResult);
      } else {
        setTokenBQuery(tokenBQueryParam);
      }

      handleTriggerChangeCurveId(curveIdParam);
    }
  }, [window.location.search, tokens]);

  const { data: tokenAQueryData } = useToken({
    address: convertAddress(tokenAQuery),
  });

  const { data: tokenBQueryData } = useToken({
    address: convertAddress(tokenBQuery),
  });

  useEffect(() => {
    if (tokenAQueryData && chain_id) {
      const token = {
        address: tokenAQueryData.address,
        chainId: chain_id?.id,
        decimals: tokenAQueryData.decimals,
        logoURI: UNKNOWN_ICON,
        name: tokenAQueryData.name,
        symbol: tokenAQueryData.symbol,
      };
      setTokenAData(token);
    }
  }, [tokenAQueryData]);

  useEffect(() => {
    if (tokenBQueryData && chain_id) {
      const token = {
        address: tokenBQueryData.address,
        chainId: chain_id?.id,
        decimals: tokenBQueryData.decimals,
        logoURI: UNKNOWN_ICON,
        name: tokenBQueryData.name,
        symbol: tokenBQueryData.symbol,
      };
      setTokenBData(token);
    }
  }, [tokenBQueryData]);

  const [tokenListType, setTokenListType] = useState<TokenListType>(
    TokenListType.from
  );

  useEffect(() => {
    setTokensToShow(tokens);
  }, [liquidityType]);

  const handleTokenSelector = () => {
    if (tokenListIsOpened === true) {
      setTokensToShow(tokens);
    }
    setTokenListIsOpened(!tokenListIsOpened);
    setTokenToSearch(undefined);
    setTokenSearchQuery('');
  };

  const handleOpenListFrom = () => {
    if (tokenBData) {
      const filtered = tokens.filter((item: any) => {
        if (tokenBData.symbol === 'AVAX' || tokenBData.symbol === 'WAVAX') {
          return item.symbol !== 'AVAX' && item.symbol !== 'WAVAX';
        } else {
          return item.symbol !== tokenBData.symbol;
        }
      });
      setTokensToShow(filtered);
    }
    setTokenListIsOpened(true);
    setTokenListType(TokenListType.from);
  };

  const handleOpenListTo = () => {
    if (tokenAData) {
      const filtered = tokens.filter((item: any) => {
        if (tokenAData.symbol === 'AVAX' || tokenAData.symbol === 'WAVAX') {
          return item.symbol !== 'AVAX' && item.symbol !== 'WAVAX';
        } else {
          return item.symbol !== tokenAData.symbol;
        }
      });
      setTokensToShow(filtered);
    }
    setTokenListIsOpened(true);
    setTokenListType(TokenListType.to);
  };

  const handleSelectToken = (token: Token) => {
    if (tokenListType === TokenListType.from) {
      setTokenAData(token);
    } else {
      setTokenBData(token);
    }
    setTokenSearchQuery('');
    setTokenToSearch(undefined);
    setTokenListIsOpened(!tokenListIsOpened);
  };

  const { data: tokenData } = useToken({
    address: convertAddress(tokenToSearch),
  });

  useEffect(() => {
    if (tokenData && chain_id) {
      const token = {
        address: tokenData.address,
        chainId: chain_id?.id,
        decimals: tokenData.decimals,
        logoURI: UNKNOWN_ICON,
        name: tokenData.name,
        symbol: tokenData.symbol,
      };
      setTokensToShow([token]);
    }
  }, [tokenData, tokenToSearch, tokenSearchQuery]);

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (tokens.length === 0) {
      dispatch(getTokens());
    }
  }, []);

  useEffect(() => {
    if (tokens.length > 0) {
      setTokensToShow(tokens);
    }
  }, [tokens.length]);

  useEffect(() => {
    if (tokenSearchQuery) {
      if (tokenSearchQuery.length === 42) {
        const filtered = tokens.filter((token: Token) => {
          return token.address.toLowerCase() === tokenSearchQuery.toLowerCase();
        });
        if (filtered.length > 0) {
          setTokensToShow(filtered);
        } else {
          setTokenToSearch(tokenSearchQuery);
        }
      } else {
        const filtered = tokens.filter((token: Token) => {
          return (
            token.name.toLowerCase().includes(tokenSearchQuery.toLowerCase()) ||
            token.symbol.toLowerCase().includes(tokenSearchQuery.toLowerCase())
          );
        });
        setTokensToShow(filtered);
      }
    } else if (tokenSearchQuery === '') {
      setTokensToShow(tokens);
    }
  }, [tokenSearchQuery]);

  // ADD LIQUIDITY

  const [calldata, setCalldata] = useState<string | undefined>(undefined);
  const [txValue, setValue] = useState<string | undefined>(undefined);
  const [tokenAAsQuote, setTokenAAsQuote] = useState<boolean>(false);
  const [curveId, setCurveId] = useState(0);
  const [currentPair, setCurrentPair] = useState<any>(null);
  const [pairTokenAReserves, setPairTokenAReserves] = useState<any>(null);
  const [pairTokenBReserves, setPairTokenBReserves] = useState<any>(null);
  const [expectedLpTokenAmt, setExpectedLpTokenAmt] = useState<any>(null);
  const provider = useProvider();

  const tokenAAmountChanged = (from_value: string) => {
    setTokenAAsQuote(true);
    setTokenAAmount(from_value);
  };

  const tokenBAmountChanged = (to_value: string) => {
    setTokenAAsQuote(false);
    setTokenBAmount(to_value);
  };

  const setMaxA = () => {
    tokenAAmountChanged(String(balanceA));
  };

  const setMaxB = () => {
    tokenBAmountChanged(String(balanceB));
  };

  const handleTokenAChange = async (newToken: Token) => {
    const tokenAddress = newToken.address;
    if (tokenAddress === AddressZero) {
      //@ts-ignore
      setTokenA(Ether.onChain(chain_id?.id));
    } else {
      const token = await Fetcher.fetchTokenData(
        //@ts-ignore
        chain_id?.id,
        tokenAddress,
        provider,
        newToken.symbol,
        newToken.name
      );
      setTokenA(token);
    }
  };

  const handleTokenBChange = async (newToken: Token) => {
    const tokenAddress = newToken.address;
    if (tokenAddress === AddressZero) {
      //@ts-ignore
      setTokenB(Ether.onChain(chain_id?.id));
    } else {
      const token = await Fetcher.fetchTokenData(
        //@ts-ignore
        chain_id?.id,
        tokenAddress,
        provider,
        newToken.symbol,
        newToken.name
      );
      setTokenB(token);
    }
  };

  const [priorityTokens, setPriorityTokens] = useState<string[]>([]);

  const handleTriggerMoreThenZero = (tokenAddressValue: any) => {
    if (!priorityTokens.includes(tokenAddressValue)) {
      setPriorityTokens((prevTokens) => {
        const updatedTokens = new Set(prevTokens);
        updatedTokens.add(tokenAddressValue);
        return Array.from(updatedTokens);
      });
    }
  };

  useEffect(() => {
    const tokensArray = tokens.sort((a: any, b: any) => {
      if (priorityTokens.includes(a.address)) {
        return -1; // Move object a to the top
      } else if (priorityTokens.includes(b.address)) {
        return 1; // Move object b to the top
      }
      return 0; // Maintain the same order
    });
    setTokensToShow(tokensArray);
  }, [priorityTokens]);

  const request = {
    to: String(ROUTER_ADDRESS(chain_id?.id)),
    value: txValue,
    data: calldata,
  };

  const { config, refetch } = usePrepareSendTransaction({
    request,
  });

  const { data: lpTotalSupplyData } = useContractRead({
    address: currentPair?.liquidityToken.address,
    abi: erc20ABI,
    functionName: 'totalSupply',
    enabled: currentPair != null,
  });

  const { sendTransaction } = useSendTransaction({
    ...config,
    async onSuccess(tx) {
      emitter.emit('watchTransaction', tx.hash);

      if (!tokenAData || !tokenBData) {
        return;
      }

      await tx.wait();

      const transaction = {
        hash: tx.hash,
        timestamp: Date.now(),
        type: 'add_liquidity',
        status: TransactionStatus.Success,
        tokenA: tokenAData.symbol,
        tokenB: tokenBData.symbol,
        creator: address,
      };

      emitter.emit('addTransaction', transaction);
    },
  });

  const calcAddLiqAmounts = async () => {
    if (tokenA === null || tokenB === null || curveId === null) {
      setIsProcessing(false);
      return;
    }
    if (
      (tokenAAmount == '0' && tokenAAsQuote) ||
      (tokenBAmount == '0' && !tokenAAsQuote)
    ) {
      setIsProcessing(false);
      return;
    }

    if (!address) {
      setIsProcessing(false);
      return;
    }

    let pair: Pair | null;

    try {
      pair = await Fetcher.fetchPairData(
        tokenA.wrapped,
        tokenB.wrapped,
        curveId,
        provider
      );
      setCurrentPair(pair);
    } catch {
      setCurrentPair(null);
      setPairTokenAReserves(null);
      setPairTokenBReserves(null);
      pair = null;
    }


    if (pair != null) {
      setIsPairExist(true);
      setPairTokenAReserves(pair.reserveOf(tokenA.wrapped).toExact());
      setPairTokenBReserves(pair.reserveOf(tokenB.wrapped).toExact());

      const lpTotalSupply = CurrencyAmount.fromRawAmount(
        pair.liquidityToken,
        //@ts-ignore+
        lpTotalSupplyData
      );

      if (tokenAAsQuote) {
        // in univ2 they use the midPrice for quoting how many of each token to add
        // but since the price calculation is different for ConstantProduct and Stable
        // we need a new function
        const liqRatio = pair.liqRatio(tokenA.wrapped);
        const tokenAQuoteAmt = CurrencyAmount.fromRawAmount(
          tokenA.wrapped,
          parseUnits(tokenAAmount, tokenA?.decimals || 18).toString()
        );
        const correspondingAmount = liqRatio.quote(tokenAQuoteAmt);
        setTokenBAmount(correspondingAmount.toExact());

        const mintedAmt = pair.getLiquidityMinted(
          lpTotalSupply,
          tokenAQuoteAmt,
          correspondingAmount
        );
        setExpectedLpTokenAmt(mintedAmt.toExact());
      } else {
        const liqRatio = pair.liqRatio(tokenB.wrapped);

        const tokenBQuoteAmt = CurrencyAmount.fromRawAmount(
          tokenB.wrapped,
          parseUnits(tokenBAmount, tokenB.decimals).toString()
        );
        const correspondingAmount = liqRatio.quote(tokenBQuoteAmt);
        setTokenAAmount(correspondingAmount.toExact());

        const mintedAmt = pair.getLiquidityMinted(
          lpTotalSupply,
          correspondingAmount,
          tokenBQuoteAmt
        );
        setExpectedLpTokenAmt(mintedAmt.toExact());
      }

      // should we move this out?
      if (tokenAAmount !== '' && tokenBAmount !== '') {
        const tokenAAmtIn = CurrencyAmount.fromRawAmount(
          tokenA,
          parseUnits(tokenAAmount, tokenA.decimals).toString()
        );
        const tokenBAmtIn = CurrencyAmount.fromRawAmount(
          tokenB,
          parseUnits(tokenBAmount, tokenB.decimals).toString()
        );


        const parameters: MethodParameters = Router.addLiquidityParameters(
          tokenAAmtIn,
          tokenBAmtIn,
          curveId,
          {
            allowedSlippage: new Percent(pair.swapFee, FEE_ACCURACY),
            recipient: toAddress(address),
          }
        );
        setCalldata(parameters.calldata);
        setValue(parameters.value);
      }
    } else {

      setIsPairExist(false);
      let amountA, amountB;
      if (tokenAAmount !== '') {
        amountA = CurrencyAmount.fromRawAmount(
          tokenA.wrapped,
          parseUnits(tokenAAmount, tokenA.decimals).toString()
        );
      }
      if (tokenBAmount !== '') {
        amountB = CurrencyAmount.fromRawAmount(
          tokenB.wrapped,
          parseUnits(tokenBAmount, tokenB.decimals).toString()
        );
      }
      //@ts-ignore
      const newPair = new Pair(amountA, amountB, curveId);

      const totalSupplyZero = CurrencyAmount.fromRawAmount(
        newPair.liquidityToken,
        '0'
      );

      const liqRatio = newPair.liqRatio(tokenA.wrapped);
      //@ts-ignore
      const correspondingAmount = liqRatio.quote(amountA);

      const mintedAmt = newPair.getLiquidityMinted(
        totalSupplyZero,
        //@ts-ignore
        amountA,
        correspondingAmount
      );

      setExpectedLpTokenAmt(mintedAmt.toExact());

      // N.B slippage also matters during the creation of a pair to guard
      // against someone frontrunning your adding liq operation
      if (amountA && amountB) {
        const parameters: MethodParameters = Router.addLiquidityParameters(
          amountA,
          correspondingAmount,
          curveId,
          {
            allowedSlippage: SLIPPAGE,
            recipient: toAddress(address),
          }
        );


        setCalldata(parameters.calldata);
        setValue(parameters.value);

        setCurrentPair(null);
      }
    }
    setIsProcessing(false);
  };

  const { data: allowanceA } = useContractRead({
    address: tokenAData?.address,
    abi: erc20ABI,
    functionName: 'allowance',
    enabled: tokenAData != null,
    watch: true,
    args: [address ? address : AddressZero, ROUTER_ADDRESS(chain_id?.id)],
  });

  const { config: approveConfigA } = usePrepareContractWrite({
    address: tokenAData?.address,
    abi: erc20ABI,
    functionName: 'approve',
    args: [ROUTER_ADDRESS(chain_id?.id), APPROVE_AMOUNT],
  });

  const [isApproveALoading, setIsApproveALoading] = useState(false);

  const { isLoading: isApproveLoadingA, writeAsync: doApproveA } =
    useContractWrite({
      ...approveConfigA,
      async onSuccess(tx) {
        setIsApproveALoading(true);
        emitter.emit('watchTransaction', tx.hash);

        await tx.wait();
        setTimeout(() => {
          setIsApproveALoading(false);
          refetch();
        }, 100);
        // const usedANumber = Number(tokenAAmount)
        // tokenAAmountChanged(String(usedANumber + 0.1))
        // tokenAAmountChanged(String(usedANumber))
      },
    });

  const { data: allowanceB } = useContractRead({
    address: tokenBData?.address,
    abi: erc20ABI,
    functionName: 'allowance',
    enabled: tokenAData != null,
    watch: true,
    args: [address ? address : AddressZero, ROUTER_ADDRESS(chain_id?.id)],
  });

  const { config: approveConfigB } = usePrepareContractWrite({
    address: tokenBData?.address,
    abi: erc20ABI,
    functionName: 'approve',
    args: [ROUTER_ADDRESS(chain_id?.id), APPROVE_AMOUNT],
  });

  const [isApproveBLoading, setIsApproveBLoading] = useState(false);

  const { isLoading: isApproveLoadingB, writeAsync: doApproveB } =
    useContractWrite({
      ...approveConfigB,
      async onSuccess(tx) {
        setIsApproveBLoading(true);
        emitter.emit('watchTransaction', tx.hash);

        await tx.wait();

        setTimeout(() => {
          setIsApproveBLoading(false);
          refetch();
        }, 100);
        // const usedBNumber = Number(tokenBAmount)
        // tokenBAmountChanged(String(usedBNumber + 0.1))
        // tokenBAmountChanged(String(usedBNumber))
      },
    });

  const doAddLiquidity = () => {
    if (
      allowanceA &&
      (allowanceA.toString() === '0' ||
        Number(allowanceA.toString()) / Math.pow(10, tokenA.decimals) <
          Number(tokenAAmount))
    ) {
      if (!doApproveA) return;
      doApproveA();
    } else if (
      allowanceB &&
      (allowanceB.toString() === '0' ||
        Number(allowanceB.toString()) / Math.pow(10, tokenB.decimals) <
          Number(tokenBAmount))
    ) {
      if (!doApproveB) return;
      doApproveB();
    } else {
      if (calldata === null && sendTransaction != null) {
        return;
      }
      //@ts-ignore
      sendTransaction();
    }
  };

  useEffect(() => {
    setIsProcessing(true);
    try {
      calcAddLiqAmounts();
    } catch (e) {
      console.log(e);
    }
  }, [
    tokenA,
    tokenB,
    tokenAAmount,
    tokenBAmount,
    curveId,
    lpTotalSupplyData,
    // allowanceA,
    // allowanceB,
    isPairExist,
    isApproveLoadingA,
    isApproveLoadingB,
  ]);

  useEffect(() => {
    setCurveId(liquidityType);
  }, [liquidityType]);

  useEffect(() => {
    if (tokenAData) {
      handleTokenAChange(tokenAData);
    }
  }, [tokenAData]);

  useEffect(() => {
    if (tokenBData) {
      handleTokenBChange(tokenBData);
    }
  }, [tokenBData]);

  return (
    <>
      <div className={style.add_liquidity__content_top}>
        <div className={`${style.add_liquidity__logo} ${style.settings}`}>
          <p className={style.add_liquidity__title}>Add liquidity</p>
          {/* <img
            src={cog}
            alt="settings"
            className={style.add_liquidity__img}
            onClick={handleSettings}
          /> */}
        </div>

        <div className={style.add_liquidity__container}>
          <div className={`${style.add_liquidity__info_top} ${style.info}`}>
            <div className={`${style.info__coins_top} ${style.coins}`}>
              <CurrencyInput
                className={style.coins__amount_top}
                id="input-example"
                name="input-name"
                autoComplete="off"
                intlConfig={{locale: 'en-US'}}
                disableGroupSeparators={true}
                value={tokenAAmount}
                defaultValue={0}
                decimalsLimit={8}
                onValueChange={(value) =>
                  value ? tokenAAmountChanged(value) : tokenAAmountChanged('0')
                }
              />
              {tokenAData ? (
                <button
                  type="button"
                  className={style.coins__list_top}
                  onClick={handleOpenListFrom}
                >
                  <img
                    src={tokenAData.logoURI}
                    alt="token logo"
                    className={style.coins__logo}
                  />
                  <p className={style.coins__title}>{tokenAData.symbol}</p>
                  <div className={style.coins__list_opener}>
                    <svg
                      width="12"
                      height="7"
                      viewBox="0 0 12 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.247993 0.228449C0.552609 -0.0761497 1.04647 -0.0761497 1.35109 0.228449L5.99954 4.87692L10.648 0.228449C10.9526 -0.0761497 11.4464 -0.0761497 11.7511 0.228449C12.0556 0.533065 12.0556 1.02693 11.7511 1.33154L6.55109 6.53154C6.40479 6.67782 6.20641 6.76 5.99954 6.76C5.79267 6.76 5.59427 6.67782 5.44799 6.53154L0.247993 1.33154C-0.0566227 1.02693 -0.0566227 0.533065 0.247993 0.228449Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </button>
              ) : (
                <button
                  type="button"
                  className={style.info__select}
                  onClick={handleOpenListFrom}
                >
                  Select token
                </button>
              )}
            </div>

            <CSSTransition
              in={tokenListIsOpened}
              nodeRef={modalRef}
              timeout={{ enter: 300, exit: 300 }}
              classNames={{
                enter: style['token__transition-enter'],
                enterActive: style['token__transition-enter-active'],
                exit: style['token__transition-exit'],
                exitActive: style['token__transition-exit-active'],
              }}
              unmountOnExit
            >
              <div ref={modalRef} className={style.token__container}>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    if (tokenListIsOpened) {
                      setTokenListIsOpened(false);
                    }
                  }}
                >
                  <div className={style.token__wrapper}>
                    <div className={style.token__wrapper_top}>
                      <div className={style.token__header}>
                        <h5 className={style.token__title}>Select a token</h5>
                        <button
                          type="button"
                          className={style.token__close}
                          onClick={handleTokenSelector}
                        >
                          <div className={style.token__close_img}>
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 1L1.05853 13"
                                stroke="#0F3846"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M1.05853 1L13 13"
                                stroke="#0F3846"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </button>
                      </div>

                      <div
                        className={`${style.token__searchbar} ${style.searchbar}`}
                      >
                        <div className={style.searchbar__icon}>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.24175 13.4444C10.6782 13.4444 13.464 10.6586 13.464 7.22221C13.464 3.78578 10.6782 1 7.24175 1C3.80531 1 1.01953 3.78578 1.01953 7.22221C1.01953 10.6586 3.80531 13.4444 7.24175 13.4444Z"
                              stroke="#F8E0BB"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.02 15L11.6367 11.6167"
                              stroke="#F8E0BB"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <input
                          type="text"
                          className={style.searchbar__input}
                          placeholder="Search name or paste address"
                          onChange={(e) => setTokenSearchQuery(e.target.value)}
                        />
                      </div>

                      {/* <div className={`${style.token__popular} ${style.popular}`}>
                      <div className={style.popular__pill}>
                        <img
                          src={ethIcon}
                          alt="eth icon"
                          className={style.popular__icon}
                        />
                        <p className={style.popular__title}>ETH</p>
                      </div>
                      <div className={style.popular__pill}>
                        <img
                          src={voirIcon}
                          alt="voir icon"
                          className={style.popular__icon}
                        />
                        <p className={style.popular__title}>VOIR</p>
                      </div>
                      <div className={style.popular__pill}>
                        <img
                          src={usdtIcon}
                          alt="usdt icon"
                          className={style.popular__icon}
                        />
                        <p className={style.popular__title}>USDT</p>
                      </div>
                    </div> */}
                    </div>

                    <div className={style.token__wrapper_bottom}>
                      <div className={style.token__list_container}>
                        {tokensToShow.map((token: Token, index) => {
                          return (
                            <div
                              className={`${style.token__list_item} ${style.item}`}
                              onClick={() => handleSelectToken(token)}
                              key={index}
                            >
                              <img
                                src={token.logoURI}
                                alt="token icon"
                                className={style.item__icon}
                              />
                              <div className={style.item__info}>
                                <h4 className={style.item__title}>
                                  {token.symbol}
                                </h4>
                                <p className={style.item__network}>
                                  {token.name}
                                </p>
                              </div>

                              <div className={style.item__details}>
                                <p className={style.item__amount}>
                                  <TokenBalance
                                    userAddress={address}
                                    tokenAddress={token.address}
                                    tokenName={token.name}
                                    triggerMoreThenZero={
                                      handleTriggerMoreThenZero
                                    }
                                  />
                                </p>
                                <p className={style.item__price}>
                                  <Price
                                    key={index}
                                    tokenName={token.name}
                                    tokenAddress={token.address}
                                  />
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </OutsideClickHandler>
              </div>
            </CSSTransition>
            {tokenAData && (
              <div
                className={`${style.add_liquidity__amount_top} ${style.amount}`}
              >
                <p className={style.amount__fiat_top}>
                  <Price
                    tokenName={tokenAData.name}
                    tokenAddress={tokenAData.address}
                    amount={tokenAAmount}
                  />
                </p>
                <p
                  className={style.amount__balance_top}
                  onClick={() => setMaxA()}
                  style={{ cursor: 'pointer' }}
                >
                  Balance:{' '}
                  <Balance
                    address={tokenAData.address}
                    triggerBalanceValue={handleBalanceA}
                  />{' '}
                  <span className="max">Max</span>
                </p>
              </div>
            )}
          </div>

          {/* <span className={style.add_liquidity__select}>
            <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M8.22846 4.53156C8.53308 4.83616 9.02694 4.83616 9.33156 4.53156L11.25 2.6131V11.76C11.25 12.1742 11.5858 12.51 12 12.51C12.4142 12.51 12.75 12.1742 12.75 11.76V2.65306L14.6285 4.53156C14.933 4.83616 15.4269 4.83616 15.7316 4.53156C16.0361 4.22694 16.0361 3.73308 15.7316 3.42846L12.5316 0.228455C12.3853 0.0821788 12.1869 0 11.98 0C11.7731 0 11.5747 0.0821788 11.4285 0.228455L8.22846 3.42846C7.92385 3.73308 7.92385 4.22694 8.22846 4.53156ZM4 0.25C4.41421 0.25 4.75 0.585786 4.75 1V10.1069L6.62849 8.22845C6.93304 7.92385 7.42687 7.92385 7.73159 8.22845C8.03614 8.53307 8.03614 9.02693 7.73159 9.33154L4.53156 12.5316C4.38527 12.6778 4.18689 12.76 3.98002 12.76C3.77314 12.76 3.57475 12.6778 3.42847 12.5316L0.228462 9.33154C-0.0761542 9.02693 -0.0761542 8.53307 0.228462 8.22845C0.533079 7.92385 1.02694 7.92385 1.33156 8.22845L3.25 10.1469V1C3.25 0.585786 3.58579 0.25 4 0.25Z" fill="#0F3846"/>
            </svg>
          </span> */}
        </div>
      </div>

      <div className={style.add_liquidity__content_bottom}>
        <div className={style.add_liquidity__info_bottom}>
          <CurrencyInput
            className={style.coins__amount_bottom}
            name="tokenBAmount"
            autoComplete="off"
            intlConfig={{locale: 'en-US'}}
            disableGroupSeparators={true}
            value={tokenBAmount}
            defaultValue={0}
            decimalsLimit={8}
            onValueChange={(value) =>
              value ? tokenBAmountChanged(value) : tokenBAmountChanged('0')
            }
          />

          {tokenBData ? (
            <button
              type="button"
              className={style.info__selected}
              onClick={handleOpenListTo}
            >
              <img
                src={tokenBData.logoURI}
                alt="token logo"
                className={style.coins__logo_selected}
              />
              <p className={style.coins__title_selected}>{tokenBData.symbol}</p>
              <div className={style.coins__list_selected}>
                <svg
                  width="12"
                  height="7"
                  viewBox="0 0 12 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.247993 0.228449C0.552609 -0.0761497 1.04647 -0.0761497 1.35109 0.228449L5.99954 4.87692L10.648 0.228449C10.9526 -0.0761497 11.4464 -0.0761497 11.7511 0.228449C12.0556 0.533065 12.0556 1.02693 11.7511 1.33154L6.55109 6.53154C6.40479 6.67782 6.20641 6.76 5.99954 6.76C5.79267 6.76 5.59427 6.67782 5.44799 6.53154L0.247993 1.33154C-0.0566227 1.02693 -0.0566227 0.533065 0.247993 0.228449Z"
                    fill="white"
                  />
                </svg>
              </div>
            </button>
          ) : (
            <button
              type="button"
              className={style.info__select}
              onClick={handleOpenListTo}
            >
              Select token
            </button>
          )}
        </div>

        {tokenBData && (
          <div
            className={`${style.add_liquidity__amount_bottom} ${style.amount}`}
          >
            <p className={style.amount__fiat_bottom}>
              <Price
                tokenName={tokenBData.name}
                tokenAddress={tokenBData.address}
                amount={tokenBAmount}
              />
            </p>
            <p
              className={style.amount__balance_bottom}
              onClick={() => setMaxB()}
              style={{ cursor: 'pointer' }}
            >
              Balance:{' '}
              <Balance
                address={tokenBData.address}
                triggerBalanceValue={handleBalanceB}
              />{' '}
              <span className="max">Max</span>
            </p>
          </div>
        )}

        {tokenAData &&
          tokenBData &&
          pairTokenAReserves &&
          pairTokenBReserves && (
            <div className={style.add_liquidity__tokens}>
              <div>Tokens in pair:</div>
              <div className={style.add_liquidity__tokens_row}>
                <span>
                  <TokenIcon tokenName={tokenAData?.name} /> {tokenAData?.name}
                </span>
                <span>{pairTokenAReserves}</span>
              </div>
              <div className={style.add_liquidity__tokens_row}>
                <span>
                  <TokenIcon tokenName={tokenBData?.name} /> {tokenBData?.name}
                </span>
                <span>{pairTokenBReserves}</span>
              </div>
            </div>
          )}

        {expectedLpTokenAmt && currentPair && (
          <div className={style.add_liquidity__info}>
            <div className={style.add_liquidity__info_title}>LP Tokens</div>
            <div className={style.add_liquidity__info_row}>
              <span>Your current LP token balance</span>
              <span>
                <Balance address={currentPair.liquidityToken.address} />
              </span>
            </div>
            <div
              className={style.add_liquidity__info_row}
              style={{ fontWeight: 500, fontSize: '16px' }}
            >
              <span>You will gain</span>
              <span>{expectedLpTokenAmt}</span>
            </div>
          </div>
        )}

        {!isPairExist && expectedLpTokenAmt !== null && (
          <>
            <span className="typeSelectedColor">
              You are creating a new pair
            </span>
            <div className={style.add_liquidity__info}>
              <div
                className={style.add_liquidity__info_row}
                style={{ fontWeight: 500, fontSize: '16px' }}
              >
                <span>You will gain</span>
                <span>{expectedLpTokenAmt}</span>
              </div>
            </div>
          </>
        )}

        <ConnectButton.Custom>
          {({
            account,
            chain,
            openChainModal,
            openConnectModal,
            authenticationStatus,
            mounted,
          }) => {
            // Note: If your app doesn't use authentication, you
            // can remove all 'authenticationStatus' checks
            const ready = mounted && authenticationStatus !== 'loading';
            const connected =
              ready &&
              account &&
              chain &&
              (!authenticationStatus ||
                authenticationStatus === 'authenticated');

            return (
              <div
                {...(!ready && {
                  'aria-hidden': true,
                  style: {
                    opacity: 0,
                    pointerEvents: 'none',
                    userSelect: 'none',
                  },
                })}
              >
                {(() => {
                  if (!connected) {
                    return (
                      <button
                        onClick={openConnectModal}
                        className={style.add_liquidity__connect}
                        type="button"
                      >
                        Connect Wallet
                      </button>
                    );
                  }

                  if (chain.unsupported) {
                    return (
                      <button
                        onClick={openChainModal}
                        className={style.add_liquidity__connect}
                        type="button"
                      >
                        Switch to Avalanche
                      </button>
                    );
                  }

                  return (
                    <button
                      className={style.add_liquidity__connect}
                      disabled={
                        currentTxHash ||
                        isApproveALoading ||
                        isApproveBLoading ||
                        isProcessing ||
                        balanceA < Number(tokenAAmount) ||
                        balanceB < Number(tokenBAmount)
                      }
                      onClick={() => doAddLiquidity()}
                      type="button"
                    >
                      {allowanceA &&
                      (allowanceA.toString() === '0' ||
                        Number(allowanceA.toString()) /
                          Math.pow(10, tokenA?.decimals || 18) <
                          Number(tokenAAmount))
                        ? isApproveALoading
                          ? 'Loading...'
                          : `Approve ${tokenAData?.symbol}`
                        : allowanceB &&
                          (allowanceB.toString() === '0' ||
                            Number(allowanceB.toString()) /
                              Math.pow(10, tokenB?.decimals || 18) <
                              Number(tokenBAmount))
                        ? isApproveBLoading
                          ? 'Loading...'
                          : `Approve ${tokenBData?.symbol}`
                        : isProcessing ||
                          currentTxHash ||
                          isApproveALoading ||
                          isApproveBLoading
                        ? 'Loading...'
                        : isPairExist
                        ? 'Add liquidity'
                        : 'Create pair'}
                    </button>
                  );
                })()}
              </div>
            );
          }}
        </ConnectButton.Custom>
      </div>
    </>
  );
};
