import React, { useState, useEffect } from 'react';
import style from './ChooseTheme.module.scss';
import cx from 'classnames';

export const ChooseTheme: React.FC = () => {
  const [isDark, setIsDark] = useState<boolean>(false);

  useEffect(() => {
    const theme = localStorage.getItem('reserv-theme');
    if (theme === 'dark') {
      setIsDark(true);
    } else {
      setIsDark(false);
    }
  });

  useEffect(() => {
    if (isDark) {
      document.querySelector('body')?.setAttribute('data-theme', 'dark');
    } else {
      document.querySelector('body')?.setAttribute('data-theme', 'light');
    }
  }, [isDark]);

  const toggleTheme = () => {
    if (!isDark === true) {
      localStorage.setItem('reserv-theme', 'dark');
    } else {
      localStorage.setItem('reserv-theme', 'light');
    }
    setIsDark(!isDark);
  };

  return (
    <>
      <div onClick={() => toggleTheme()} className={style.chooseTheme}>
        <div className={cx(style.chooseTheme_wrapper, !isDark && style.active)}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_427_91)">
              <path
                d="M7.99984 11.3333C9.84079 11.3333 11.3332 9.84094 11.3332 7.99999C11.3332 6.15904 9.84079 4.66666 7.99984 4.66666C6.15889 4.66666 4.6665 6.15904 4.6665 7.99999C4.6665 9.84094 6.15889 11.3333 7.99984 11.3333Z"
                stroke="#F8E0BB"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 0.666656V1.99999"
                stroke="#F8E0BB"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 14V15.3333"
                stroke="#F8E0BB"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.81348 2.81332L3.76014 3.75999"
                stroke="#F8E0BB"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.2402 12.24L13.1869 13.1867"
                stroke="#F8E0BB"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.666504 8H1.99984"
                stroke="#F8E0BB"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 8H15.3333"
                stroke="#F8E0BB"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.81348 13.1867L3.76014 12.24"
                stroke="#F8E0BB"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.2402 3.75999L13.1869 2.81332"
                stroke="#F8E0BB"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_427_91">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className={cx(style.chooseTheme_wrapper, isDark && style.active)}>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.7731 7.7125C13.6769 7.61609 13.5563 7.5477 13.4241 7.51467C13.292 7.48164 13.1534 7.48521 13.0231 7.525C12.1132 7.79969 11.1458 7.82243 10.224 7.59077C9.30215 7.35912 8.46042 6.88176 7.78846 6.20954C7.11649 5.53731 6.63945 4.69541 6.40815 3.77349C6.17685 2.85158 6.19995 1.88419 6.47499 0.974373C6.51512 0.844062 6.51896 0.705279 6.48611 0.572947C6.45325 0.440616 6.38495 0.319745 6.28853 0.223332C6.19212 0.126919 6.07125 0.058613 5.93892 0.0257595C5.80659 -0.00709399 5.6678 -0.0032518 5.53749 0.0368729C4.16899 0.457495 2.96805 1.2991 2.10562 2.44187C1.35143 3.44431 0.891513 4.63707 0.777467 5.88634C0.663421 7.1356 0.899761 8.39193 1.45997 9.51436C2.02017 10.6368 2.88208 11.5809 3.94897 12.2407C5.01587 12.9006 6.24553 13.2501 7.49999 13.25C8.96459 13.2543 10.3902 12.7781 11.5581 11.8944C12.7003 11.0311 13.541 9.82948 13.9606 8.46062C14 8.33063 14.0034 8.19237 13.9704 8.06061C13.9374 7.92885 13.8692 7.80853 13.7731 7.7125ZM10.6556 10.6962C9.64468 11.4573 8.39289 11.8276 7.13061 11.739C5.86834 11.6505 4.68052 11.109 3.78577 10.2142C2.89101 9.31947 2.34952 8.13165 2.26095 6.86938C2.17238 5.6071 2.5427 4.35531 3.30374 3.34437C3.7042 2.81466 4.2005 2.3648 4.76687 2.01812C4.75562 2.17812 4.74999 2.33875 4.74999 2.5C4.75198 4.2896 5.46377 6.00534 6.72922 7.27078C7.99466 8.53622 9.71039 9.24801 11.5 9.25C11.6612 9.25 11.8219 9.24437 11.9819 9.23312C11.6354 9.79962 11.1855 10.2959 10.6556 10.6962Z"
              fill="#0F3846"
            />
          </svg>
        </div>
      </div>
    </>
  );
};
