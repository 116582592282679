export enum Tab {
  default = 'default',
  transactions = 'transactions',
}

export enum TransactionStatus {
  Success = 'Success',
  Failed = 'Failed',
}

export enum TransactionType {
  swap = 'swap',
  add_liquidity = 'add_liquidity',
  remove_liquidity = 'remove_liquidity',
}

export enum LiquidityType {
  classic = 0,
  stable = 1,
}

export enum LiquidityAction {
  add = 'Add',
  remove = 'Remove',
}

export enum Routes {
  swap = '/swap',
  addLiquidity = '/add_liquidity',
  removeLiquidity = '/remove_liquidity',
  analytics = '/analytics',
}
