import React, { MutableRefObject, useState } from 'react';
import style from './Settings.module.scss';
import OutsideClickHandler from 'react-outside-click-handler';

const MIN = 0.01;
const MAX = 20;

type Props = {
  settingsRef: MutableRefObject<null>;
  isHidden: boolean;
  onClose: () => void;
  onSlippageChange: (value: any) => void;
};

export const Settings: React.FC<Props> = ({
  settingsRef,
  onClose,
  onSlippageChange,
  isHidden,
}) => {
  const [slippageValue, setSlippageValue] = useState<string | number>('0.1');
  const [isError, setIsError] = useState(false);
  const [isOk, setIsOk] = useState(false);

  const setAuto = () => {
    setIsError(false);
    setSlippageValue(1);
    onSlippageChange(1);
  };

  const handleInput = (event: any) => {
    const value = event.target.value;
    setIsOk(false);
    if (Number(value) >= MIN && Number(value) <= MAX) {
      setIsError(false);
      setSlippageValue(parseFloat(value));
    } else {
      if (event.target.value == '0.0') {
        setSlippageValue(event.target.value);
      } else {
        setSlippageValue(parseFloat(value));
        setIsError(true);
      }
    }
  };

  const handleOnClose = () => {
    if (slippageValue >= MIN && slippageValue <= MAX) {
      onClose();
    } else {
      setAuto();
      onClose();
    }
  };

  const confirm = () => {
    onSlippageChange(slippageValue);
    setIsError(false);
    setIsOk(true);
    onClose();
  };

  return (
    <div
      ref={settingsRef}
      className={style.settings__page}
      style={{ display: isHidden ? 'none' : 'block' }}
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          if (!isHidden) {
            onClose();
          }
        }}
      >
        <div className={style.settings__content}>
          <div className={style.settings__content_wrapper}>
            <div className={style.settings__header}>
              <h5 className={style.settings__title}>Settings</h5>

              <button
                type="button"
                className={style.settings__close}
                onClick={() => handleOnClose()}
              >
                <div className={style.settings__close_img}>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 1L1.05853 13"
                      stroke="#0F3846"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.05853 1L13 13"
                      stroke="#0F3846"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </button>
            </div>

            <p className={style.settings__slippage_title}>Slippage tolerance</p>

            <div className={style.settings__slippage_container}>
              <button
                type="button"
                className={style.settings__slippage_set}
                onClick={setAuto}
                disabled={isOk}
              >
                Auto (1%)
              </button>

              <input
                type="number"
                className={`${style.settings__slippage_number} ${
                  isError ? 'inputError' : ''
                }`}
                style={{
                  border: isOk ? '1px solid green' : '1px solid transparent',
                }}
                name="slippage"
                placeholder="0.10%"
                value={slippageValue}
                onChange={event => handleInput(event)}
                min={0.01}
                max={20}
              />
              <span className={style.settings__slippage_number_percent}>%</span>
            </div>
            {isError && (
              <div className="errorMessage">
                Provide a number between 0.01 and 20.00
              </div>
            )}
            <div className="confirmButton">
              <button
                className={style.settings__slippage_set}
                disabled={isError}
                onClick={confirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};
