import types from '../reducers/price/types';
import CoingeckoApi from '../../api/Coingecko';
import { store } from '../store';
let processingRequests: string[] = [];

export const getAvaxPrice =
  () => (dispatch: (arg0: { type: string; data: number }) => void) => {
    return new CoingeckoApi()
      .getAvaxPrice()
      .then(response => {
        const price = response?.tickers[0].last;
        dispatch({
          type: types.UPDATE_AVAX_PRICE,
          data: price,
        });
        return price;
      })
      .catch((error: any) => {
        throw error;
      });
  };

export const getTokenPrice =
  (query: string) =>
  async (dispatch: (arg0: { type: string; data: any }) => void) => {
    if (processingRequests.includes(query)) return;
    processingRequests.push(query);
    const state = store.getState();
    let tokenId;
    let thumb;
    if (state.priceReducer[query] && state.priceReducer[query].id) {
      tokenId = state.priceReducer[query].thumb;
      thumb = state.priceReducer[query].thumb;
    } else {
      const searchResult = await new CoingeckoApi().searchToken(
        query.replace('Token', '').replace('WAVAX', 'AVAX')
      );
      tokenId = searchResult?.coins[0].id;
      thumb = searchResult?.coins[0].thumb;
    }

    if (
      (!state.priceReducer[query] ||
        state.priceReducer[query].lastUpdate >= Date.now() + 60 * 1000) &&
      // added this condition because occasionally tokenId is undefined. When does this happen?
      tokenId
    ) {
      const tokenPrice = await new CoingeckoApi().getTokenPrice(tokenId);
      dispatch({
        type: types.GET_TOKEN_PRICE,
        data: {
          symbol: query,
          price: tokenPrice,
          id: tokenId,
          thumb,
          lastUpdate: Date.now(),
        },
      });
    }
    processingRequests = processingRequests.filter(item => item !== query);
  };
