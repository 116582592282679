import React, { useState, useEffect } from 'react';
import style from './RemoveLiquidity.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState, AppDispatch } from '../../../redux/store';
import CurrencyInput from 'react-currency-input-field';
import { ConnectButton } from '../../../rainbow';
import { getTokens } from '../../../redux/actions/tokens';
import { TokenIcon } from '../../TokenIcon';
import { getTokenPrice } from '../../../redux/actions/price';
import { MaxUint256 } from '@ethersproject/constants';
import { BigNumber } from '@ethersproject/bignumber';
import Countdown from 'react-countdown';
import { splitSignature } from 'ethers/lib/utils';
import { Signature } from 'ethers';

import { ROUTER_ADDRESS, toAddress } from '../../../constants';
import LP_TOKEN_ABI from '../../../abi/lpTokenABI.json';
import {
  erc20ABI,
  useAccount,
  useBalance,
  useContractRead,
  useSendTransaction,
  usePrepareSendTransaction,
  useProvider,
  useNetwork,
  useSignTypedData,
} from 'wagmi';
import {
  Fetcher,
  Router,
  FEE_ACCURACY,
  MethodParameters,
  PermitOptions,
} from '@reservoir-labs/sdk';
import {
  CurrencyAmount,
  Ether,
  Percent,
  WETH9,
} from '@reservoir-labs/sdk-core';
import { LiquidityToken } from './LiquidityToken';
import { parseUnits } from '@ethersproject/units';
import emitter from '../../../events';
import { TransactionStatus } from '../../../enums';

const deadline = BigNumber.from(
  Number((Date.now() / 1000).toFixed(0)) + 30 * 60
);

export const RemoveLiquidity: React.FC = () => {
  const [unwrap, setUnwrap] = useState(false);

  const toggleUnwrap = () => {
    setUnwrap(!unwrap);
  };

  const { chain: chain_id } = useNetwork();

  const tokens = useSelector((state: RootState) => state.tokensReducer.tokens);

  const dispatch: AppDispatch = useDispatch();

  const currentTxHash = useSelector(
    (state: RootState) => state.transactionsReducer.current_transaction
  );

  useEffect(() => {
    if (tokens.length === 0) {
      dispatch(getTokens());
    }
  }, []);

  const provider = useProvider();

  const [allPairs, setAllPairs] = useState<string[] | null>(null);

  const [activeToken, setActiveToken] = useState('');
  const [pair, setPair] = useState<any>(null);
  const [calldata, setCalldata] = useState<string | undefined>(undefined);
  const { address } = useAccount();
  const [redeemAmountInput, setRedeemAmountInput] = useState('0');
  const [tokenAAmount, setTokenAAmount] = useState('');
  const [tokenBAmount, setTokenBAmount] = useState('');
  const [zeroBalance, setZeroBalance] = useState(false);
  const [usdPrice, setUsdPrice] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [amountGreater, setAmountGreater] = useState(false);

  const token0 = useSelector(
    (state: RootState) => state.priceReducer[pair?.token0.symbol]
  );
  const token1 = useSelector(
    (state: RootState) => state.priceReducer[pair?.token1.symbol]
  );

  const handleUSDPrice = async () => {
    await dispatch(getTokenPrice(pair.token0.symbol));
    await dispatch(getTokenPrice(pair.token1.symbol));

    const sum = Number(
      Number(
        Number(tokenAAmount) * token0.price +
          Number(tokenBAmount) * token1.price
      ).toFixed(3)
    );

    setUsdPrice(sum || 0);
  };

  const getDomain = () => {
    return {
      chainId: chain_id?.id,
      name: 'Reservoir LP Token',
      version: '1',
      //@ts-ignore
      verifyingContract: activeToken,
    };
  };

  const [nonce, setNonce] = useState<number>(0);

  const { data: nonceValue } = useContractRead({
    abi: LP_TOKEN_ABI,
    address: pair?.liquidityToken.address,
    functionName: 'nonces',
    enabled: pair != null,
    watch: true,
    args: [address],
  });

  useEffect(() => {
    //@ts-ignore
    if (nonceValue) {
      setNonce(Number(nonceValue.toString()));
    }
  }, [nonceValue]);

  const dataTypes = {
    Permit: [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' },
    ],
  } as const;

  const permitValues = {
    owner: address,
    spender: toAddress(ROUTER_ADDRESS(chain_id?.id)),
    value: MaxUint256,
    nonce: BigNumber.from(nonce),
    deadline,
  };

  const { data: permitData, signTypedData } = useSignTypedData({
    //@ts-ignore
    domain: getDomain(),
    //@ts-ignore
    types: dataTypes,
    //@ts-ignore
    value: permitValues,
  });

  const [isPermitActive, setIsPermitActive] = useState<boolean>(true);

  const signPermit = () => {
    signTypedData();
  };

  useEffect(() => {
    if (permitData) {
      setIsPermitActive(false);
    }
  }, [permitData]);

  const { data: allowance } = useContractRead({
    //@ts-ignore
    address: activeToken,
    abi: erc20ABI,
    functionName: 'allowance',
    watch: true,
    enabled: activeToken !== '',
    //@ts-ignore
    args: [address, ROUTER_ADDRESS(chain_id?.id)],
  });

  const checkPermit = () => {
    if (
      allowance &&
      activeToken &&
      Number(redeemAmountInput) <=
        Number(allowance?.toString()) / Math.pow(10, 18)
    ) {
      setIsPermitActive(false);
    } else {
      setIsPermitActive(true);
    }
  };

  useEffect(() => {
    if (activeToken) {
      checkPermit();
    }
  }, [activeToken, redeemAmountInput, allowance]);

  useEffect(() => {
    if (pair && tokenAAmount && tokenBAmount) {
      handleUSDPrice();
    }
  }, [pair, tokenAAmount, tokenBAmount]);

  useEffect(() => {
    setTimeout(() => {
      const elements = document.querySelectorAll('.lpBalance');
      let total = 0;
      for (let i = 0; i < elements.length; i++) {
        total += Number(elements[i].textContent);
      }

      if (total == 0) {
        setZeroBalance(true);
      }
    }, 15000);
  }, [allPairs]);

  const { config } = usePrepareSendTransaction({
    request: {
      to: ROUTER_ADDRESS(chain_id?.id),
      value: 0,
      data: calldata,
    },
    enabled: calldata != null,
  });

  const { sendTransaction } = useSendTransaction({
    ...config,
    async onSuccess(tx) {
      emitter.emit('watchTransaction', tx.hash);

      await tx.wait();

      const transaction = {
        hash: tx.hash,
        timestamp: Date.now(),
        type: 'remove_liquidity',
        status: TransactionStatus.Success,
        tokenA: pair.token0.symbol,
        tokenB: pair.token1.symbol,
        creator: address,
      };

      emitter.emit('addTransaction', transaction);
    },
  });

  const { data: lpTotalSupply } = useContractRead({
    address: pair?.liquidityToken.address,
    abi: erc20ABI,
    functionName: 'totalSupply',
  });

  const { data: lpTokenBalance } = useBalance({
    token: toAddress(activeToken),
    chainId: chain_id?.id,
    address,
    watch: true,
    enabled: activeToken != '' && address != null,
  });

  const setMax = () => {
    if (lpTokenBalance) {
      setRedeemAmountInput(lpTokenBalance.formatted);
    }
  };

  const handleClickLiquidityToken = (pairAddress: string, pairData: any) => {
    setPair(pairData);
    setActiveToken(pairAddress);
  };

  useEffect(() => {
    if (activeToken) {
      setMax();
    }
  }, [lpTokenBalance]);

  // useEffect(() => {
  //   if (
  //     lpTokenBalance &&
  //     Number(redeemAmountInput) > Number(lpTokenBalance.formatted)
  //   ) {
  //     setRedeemAmountInput(lpTokenBalance.formatted);
  //   }
  // }, [lpTokenBalance]);

  // const { config: approveConfig } = usePrepareContractWrite({
  //   address: pair?.liquidityToken.address,
  //   abi: erc20ABI,
  //   functionName: 'approve',
  //   args: [ROUTER_ADDRESS(chain_id?.id), MaxUint256],
  // });

  // const {
  //   data,
  //   isLoading: isApproveLoading,
  //   isSuccess,
  //   write: doApprove,
  // } = useContractWrite(approveConfig);

  const doRemoveLiq = () => {
    // if (allowance && allowance.toString() === '0') {
    //   if (!doApprove) return;
    //   doApprove();
    // } else {
    if (calldata === null || sendTransaction == null) {
      return;
    }

    sendTransaction();
    // }
  };

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return '';
    } else {
      // Render a countdown
      return (
        <span>
          {minutes >= 10 ? minutes : `0${minutes}`}:
          {seconds >= 10 ? seconds : `0${seconds}`}
        </span>
      );
    }
  };

  const renderPermitText = () => {
    if (permitData) {
      return <Countdown date={Number(deadline) * 1000} renderer={renderer} />;
    } else {
      return '';
    }
  };

  const calc = () => {
    setIsProcessing(true);
    if (
      pair === null ||
      redeemAmountInput == '0' ||
      !lpTotalSupply ||
      Number(redeemAmountInput) > Number(lpTokenBalance?.formatted)
    ) {
      setIsProcessing(false);
      return;
    }

    const totalSupply = CurrencyAmount.fromRawAmount(
      pair.liquidityToken,
      //@ts-ignore
      lpTotalSupply
    );

    const redeemAmount = CurrencyAmount.fromRawAmount(
      pair.liquidityToken,
      parseUnits(redeemAmountInput, pair.liquidityToken.decimals).toString()
    );

    let token0Amt = pair.getLiquidityValue(
      pair.token0,
      totalSupply,
      redeemAmount
    );
    let token1Amt = pair.getLiquidityValue(
      pair.token1,
      totalSupply,
      redeemAmount
    );

    // N.B if the user wants to unwrap the wrapped native token
    // we need to pass in the native currency (amount) to the sdk encoding function
    if (unwrap && chain_id) {
      if (token0Amt.currency.address === WETH9[chain_id?.id].address) {
        token0Amt = CurrencyAmount.fromRawAmount(
          Ether.onChain(chain_id?.id),
          token0Amt.quotient
        );
        setIsProcessing(false);
      }
      if (token1Amt.currency.address === WETH9[chain_id?.id].address) {
        token1Amt = CurrencyAmount.fromRawAmount(
          Ether.onChain(chain_id?.id),
          token1Amt.quotient
        );
        setIsProcessing(false);
      }
    }

    setTokenAAmount(token0Amt.toExact());
    setTokenBAmount(token1Amt.toExact());
    let permitOptions: PermitOptions | undefined;
    if (permitData && isPermitActive) {
      const sigComponents: Signature = splitSignature(permitData);
      permitOptions = {
        //@ts-ignore
        v: sigComponents.v,
        r: sigComponents.r,
        s: sigComponents.s,
        amount: MaxUint256.toString(),
        deadline: deadline.toString(),
      };
    }

    // N.B it is important for the frontend to validate that the pair as selected by the user exists
    // as the sdk function does not perform this check
    // if invalid tokenA/B is provided the call will revert
    // this is unlikely to happen if we use getLiquidityValue
    const parameters: MethodParameters = Router.removeLiquidityParameters(
      token0Amt,
      token1Amt,
      pair.curveId,
      redeemAmount,
      {
        allowedSlippage: new Percent(pair.swapFee, FEE_ACCURACY),
        recipient: String(address),
      },
      permitOptions
    );
    setIsProcessing(false);

    setCalldata(parameters.calldata);
  };

  useEffect(() => {
    const fetchPairs = async () => {
      if (!provider || allPairs !== null) {
        return;
      }
      const allPairsData = await Fetcher.fetchAllPairs(
        chain_id?.id || 43113,
        provider
      );
      setAllPairs(allPairsData);
    };
    fetchPairs();
  }, []);

  useEffect(calc, [
    redeemAmountInput,
    pair,
    unwrap,
    permitData,
    activeToken,
    lpTokenBalance,
    lpTotalSupply,
  ]);

  return (
    <>
      <div className={style.remove_liquidity__content_top}>
        <div className={`${style.remove_liquidity__logo} ${style.settings}`}>
          <p className={style.remove_liquidity__title}>Remove liquidity</p>
        </div>

        <div className={style.remove_liquidity__container}>
          {!address && (
            <span className="notConnected">
              Connect wallet to see pairs you have LP tokens for:
            </span>
          )}
          {address && (
            <div
              className={`${style.remove_liquidity__info_top} ${style.info}`}
            >
              <div className={`${style.info__coins_top} ${style.coins}`}>
                <p className={style.coins__pool}>
                  List of pairs you have LP tokens for:
                </p>
                {zeroBalance && (
                  <div className="emptyLp">
                    You don’t have any pairs to remove liquidity from. <br></br>
                    They will appear here once you add liquidity
                  </div>
                )}
                {allPairs !== null &&
                  allPairs.map(pair_address => {
                    return (
                      <LiquidityToken
                        key={pair_address}
                        address={pair_address}
                        onClick={pairData =>
                          handleClickLiquidityToken(pair_address, pairData)
                        }
                        activeToken={activeToken}
                      />
                    );
                  })}
              </div>
              <div
                className={`${style.remove_liquidity__amount_top} ${style.amount}`}
              ></div>
            </div>
          )}
        </div>
      </div>

      <div className={style.remove_liquidity__content_bottom}>
        {!zeroBalance && (
          <>
            <div className={style.remove_liquidity__info_bottom}>
              <CurrencyInput
                className={style.info__balance}
                id="remove_liquidity"
                name="remove_liquidity"
                autoComplete="off"
                intlConfig={{locale: 'en-US'}}
                disableGroupSeparators={true}
                value={redeemAmountInput}
                defaultValue={0}
                decimalsLimit={pair ? pair.liquidityToken.decimals : 8}
                onValueChange={value => {
                  const parsedValue = value ? value : '0';
                  if (lpTokenBalance) {
                    setRedeemAmountInput(parsedValue);

                    if (
                      Number(parsedValue) <= Number(lpTokenBalance?.formatted)
                    ) {
                      setAmountGreater(false);
                    } else {
                      setAmountGreater(true);
                    }
                  }
                }}
              />
            </div>

            <div className={style.remove_liquidity__amount_bottom}>
              <p className={style.amount__fiat_bottom}>${usdPrice}</p>
              <p className={style.amount__balance_bottom}>
                Your LP token balance:{' '}
                {activeToken && lpTokenBalance
                  ? Number(lpTokenBalance.formatted).toFixed(6)
                  : 0}{' '}
                <span
                  className="max"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setMax()}
                >
                  Max
                </span>
              </p>
            </div>
          </>
        )}
        {amountGreater && (
          <div style={{ padding: '0 0 15px', color: 'red' }}>
            The entered amount is greater than the available balance
          </div>
        )}
        {(pair?.token0.symbol === 'WAVAX' ||
          pair?.token1.symbol === 'WAVAX') && (
          <div className={style.advanced__info__unwrap}>
            <input
              type="checkbox"
              onClick={() => toggleUnwrap()}
              checked={unwrap}
            />
            Unwrap WAVAX
          </div>
        )}
        {!zeroBalance && (
          <div className={style.advanced__info}>
            <div className={style.advanced__details_info}>
              <div className={style.advanced__priceimpact}>
                <p className={style.advanced__priceimpact_title}>
                  You will obtain the following tokens if you remove liquidity:
                </p>
              </div>
              {tokenAAmount && tokenBAmount && (
                <div className={style.advanced__received}>
                  <p className={style.advanced__received_amount}>
                    <TokenIcon tokenName={pair.token0.symbol || 'avax'} />{' '}
                    <span>
                      {tokenAAmount ? tokenAAmount : 0}{' '}
                      {pair?.token0.symbol === 'WAVAX' && unwrap
                        ? 'AVAX'
                        : pair?.token0.symbol}
                    </span>
                  </p>
                  <p className={style.advanced__received_amount}>
                    <TokenIcon tokenName={pair.token1.symbol || 'avax'} />{' '}
                    <span>
                      {tokenBAmount ? tokenBAmount : 0}{' '}
                      {pair?.token1.symbol === 'WAVAX' && unwrap
                        ? 'AVAX'
                        : pair?.token1.symbol}
                    </span>
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
        <ConnectButton.Custom>
          {({
            account,
            chain,
            openChainModal,
            openConnectModal,
            authenticationStatus,
            mounted,
          }) => {
            // Note: If your app doesn't use authentication, you
            // can remove all 'authenticationStatus' checks
            const ready = mounted && authenticationStatus !== 'loading';
            const connected =
              ready &&
              account &&
              chain &&
              (!authenticationStatus ||
                authenticationStatus === 'authenticated');

            return (
              <div
                {...(!ready && {
                  'aria-hidden': true,
                  style: {
                    opacity: 0,
                    pointerEvents: 'none',
                    userSelect: 'none',
                  },
                })}
              >
                {(() => {
                  if (!connected) {
                    return (
                      <button
                        onClick={openConnectModal}
                        className={style.remove_liquidity__connect}
                        type="button"
                      >
                        Connect Wallet
                      </button>
                    );
                  }

                  if (chain.unsupported) {
                    return (
                      <button
                        onClick={openChainModal}
                        className={style.remove_liquidity__connect}
                        type="button"
                      >
                        Switch to Avalanche
                      </button>
                    );
                  }

                  return (
                    <button
                      className={style.remove_liquidity__connect}
                      onClick={() =>
                        !isPermitActive ? doRemoveLiq() : signPermit()
                      }
                      disabled={
                        isProcessing ||
                        currentTxHash ||
                        zeroBalance ||
                        amountGreater ||
                        !activeToken
                      }
                      type="button"
                    >
                      {/* {(allowance && allowance.toString() === '0' )
                        ? isApproveLoading
                          ? 'Loading...'
                          : 'Permit'
                        : isProcessing || currentTxHash
                        ? 'Loading...'
                        : 'Remove Liquidity'} */}

                      {!activeToken ? (
                        'Choose LP'
                      ) : (
                        <>
                          {isPermitActive ? (
                            'Permit'
                          ) : (
                            <>
                              <span>Remove Liquidity</span> {renderPermitText()}
                            </>
                          )}
                        </>
                      )}
                    </button>
                  );
                })()}
              </div>
            );
          }}
        </ConnectButton.Custom>
      </div>
    </>
  );
};
