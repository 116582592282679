import { applyMiddleware, createStore } from 'redux';
import * as thunkMiddleware from 'redux-thunk';
import reducers from '../reducers';

const middleware = [thunkMiddleware.default];

const store: any = createStore(reducers, applyMiddleware(...middleware));

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export { store };
