import types from '../reducers/transactions/types';
import { TransactionPayload } from '../../interfaces';

export const loadTransactions =
  () =>
  (dispatch: (arg0: { type: string; data: TransactionPayload[] }) => void) => {
    const transactions = localStorage.getItem('transactions');
    if (transactions === null)
      return dispatch({
        type: types.LOAD_TRANSACTIONS,
        data: [],
      });

    return dispatch({
      type: types.LOAD_TRANSACTIONS,
      data: JSON.parse(transactions).reverse(),
    });
  };

export const setCurrentTransaction =
  (hash: string) =>
  (dispatch: (arg0: { type: string; data: string }) => void) => {
    return dispatch({
      type: types.SET_ACTIVE_TRANSACTION,
      data: hash,
    });
  };
