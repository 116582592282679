import '@rainbow-me/rainbowkit/styles.css';

import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import { coinbaseWallet, injectedWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';
import { configureChains, createClient } from 'wagmi';
import { avalancheFuji, avalanche } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

const WALLETCONNECT_PROJECT_ID = '61bf42602a07827ec0b50f9cc4a00b03';

export const { chains, provider } = configureChains(
  [
    avalanche,
    avalancheFuji,
  ],
  [publicProvider()],
  { pollingInterval: 30_000 }
);

export const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      // we prefer injectedWallet over metamaskWallet as it works with both metamask and Core
      injectedWallet({ chains }),
      // disabling the following as specifying this option will make the injected option not show up
      // metaMaskWallet({projectId: WALLETCONNECT_PROJECT_ID, chains }),
    ],
  },
  {
    groupName: 'Others',
    wallets: [
      coinbaseWallet({ appName: 'Reservoir Finance', chains }),
      // disabling for now cuz it's working weird
      // trustWallet({ projectId: WALLETCONNECT_PROJECT_ID, chains }),
      walletConnectWallet({ projectId: WALLETCONNECT_PROJECT_ID, chains }),
    ],
  }
]);

export const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

export { RainbowKitProvider, ConnectButton } from '@rainbow-me/rainbowkit';
export { WagmiConfig } from 'wagmi';
