import { IAction, TransactionsAppState } from '../../types/index';
import types from './types';

const initialState: TransactionsAppState = {
  transactions: [],
  current_transaction: '',
};

const priceReducer = (
  state = initialState,
  action: IAction<string | boolean>
) => {
  switch (action.type) {
    case types.LOAD_TRANSACTIONS:
      return {
        ...state,
        transactions: action?.data,
      };
    case types.SET_ACTIVE_TRANSACTION:
      return {
        ...state,
        current_transaction: action?.data,
      };
    default:
      return state;
  }
};

export default priceReducer;
