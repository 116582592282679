import React, { useEffect, useState } from 'react';
import { toAddress } from '../../constants';
import { AddressZero } from '@ethersproject/constants';
import { useBalance, useAccount, useNetwork } from 'wagmi';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getTokenPrice } from '../../redux/actions/price';

interface PriceProps {
  tokenName: string;
  tokenAddress?: string;
  amount?: string | null;
}

export const Price: React.FC<PriceProps> = ({
  tokenName,
  tokenAddress,
  amount = null,
}) => {
  const { address: userAddress } = useAccount();
  const { chain } = useNetwork();
  const [tokenAmount, setTokenAmount] = useState<string | number>(0);
  const token = useSelector(
    (state: RootState) => state.priceReducer[tokenName]
  );
  const dispatch: AppDispatch = useDispatch();

  const { data } = useBalance({
    token: tokenAddress === AddressZero ? undefined : toAddress(tokenAddress),
    chainId: chain?.id,
    address: userAddress,
    watch: true,
    enabled: tokenAddress != null && userAddress != null,
  });

  const getPrice = async () => {
    await dispatch(getTokenPrice(tokenName));
  };

  useEffect(() => {
    if (Number(tokenAmount) > 0) {
      getPrice();
    }
  }, [tokenName]);

  useEffect(() => {
    if (amount === null) {
      if (data) {
        setTokenAmount(data.formatted);
      }
    } else {
      setTokenAmount(amount);
    }
  }, [data, amount]);

  return (
    <span>
      $
      {tokenAmount && token
        ? Number(Number(tokenAmount) * token.price).toFixed(2)
        : 0}
    </span>
  );
};
