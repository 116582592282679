import React, { useEffect, useState } from 'react';
import style from './Network.module.scss';
import { useNetwork } from 'wagmi';
import chains from '../../../chains.json';
import { TokenIcon } from '../../TokenIcon';
import { getTokens } from '../../../redux/actions/tokens';
import { useDispatch } from 'react-redux';
import type { AppDispatch } from '../../../redux/store';

export const Network: React.FC = () => {
  const [showNetworkTooltip, setShowNetworkTooltip] = useState<boolean>(false);
  const [currentChain, setCurrentChain] = useState<any>(null);
  const { chain } = useNetwork();

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (chain) {
      const current = [...chains].filter(
        item => item.chainId === Number(chain?.id)
      );
      setCurrentChain(current[0]);
      //@ts-ignore
      window.chainID = chain.id;
      //@ts-ignore
      dispatch(getTokens());
    }
  }, [chain]);
  return (
    <>
      {currentChain && (
        <div
          className={style.network}
          onMouseEnter={() => setShowNetworkTooltip(true)}
          onMouseLeave={() => setShowNetworkTooltip(false)}
        >
          <TokenIcon tokenName={currentChain.icon} />
          {currentChain?.name}
          {chain?.unsupported && (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.99967 14.6666C4.31767 14.6666 1.33301 11.6819 1.33301 7.99992C1.33301 4.31792 4.31767 1.33325 7.99967 1.33325C11.6817 1.33325 14.6663 4.31792 14.6663 7.99992C14.6663 11.6819 11.6817 14.6666 7.99967 14.6666ZM7.33301 9.99992V11.3333H8.66634V9.99992H7.33301ZM7.33301 4.66659V8.66659H8.66634V4.66659H7.33301Z"
                fill="#FF5500"
              />
            </svg>
          )}
          <div
            className={style.network__tooltip_wrapper}
            style={
              showNetworkTooltip
                ? { visibility: 'visible' }
                : { visibility: 'hidden' }
            }
          >
            <p className={style.network__tooltip_text}>
              {chain?.unsupported ? (
                <span>
                  This network is not supported.<br></br> Please switch to a
                  different network
                </span>
              ) : (
                'Other chains coming soon'
              )}
            </p>
            {/* {!chain?.unsupported && (
                    <ul>
                      <li>
                        <img src={chain1} /> Ethereum
                      </li>
                      <li>
                      <img src={chain2} /> Polygon
                      </li>
                      <li>
                      <img src={chain3} />  BNB Chain
                      </li>
                    </ul>
                  )} */}
          </div>
        </div>
      )}
    </>
  );
};
