import Base from './Base';

export default class CoingeckoApi extends Base {
  getAvaxPrice() {
    return super.get('https://api.coingecko.com/api/v3/coins/avalanche-2');
  }

  async getTokens() {
    const response = await super.get(
      'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/mc.tokenlist.json'
    );
    const filtered = response.tokens.filter(
      (item: any) => item.chainId === 43114
    );
    return filtered;
  }

  searchToken(query: string) {
    const processedQuery = query === 'Wrapped Avalanche' ? 'wavax' : query;
    return super.get(
      `https://api.coingecko.com/api/v3/search?query=${processedQuery.replace('.e', '')}`
    );
  }

  async getTokenPrice(id: string) {
    const payload = {
      ids: id,
      vs_currencies: 'USD',
    };
    const priceResponse = await super.get(
      `https://api.coingecko.com/api/v3/simple/price`,
      { params: payload }
    );

    if (id && priceResponse) {
      return priceResponse[id]?.usd || 0;
    } else {
      return 0;
    }
  }
}
