import { Percent, WETH9 } from '@reservoir-labs/sdk-core';
import { AddressZero } from '@ethersproject/constants';
import { BigNumber } from 'ethers';
import { Address } from 'wagmi';

import { ROUTER_ADDRESS as RESERV_ROUTER_ADDRESS } from '@reservoir-labs/sdk';
import type { Token } from './interfaces';

import usdtIcon from './assets/images/usdtIcon.png';
import usdcIcon from './assets/images/usdc.png';
import avaxIcon from './assets/images/avax.png';
import wavaxIcon from './assets/images/wavax.png';

import unknownIcon from './assets/images/unknown.png';

export const toAddress = (value: string | undefined): Address | undefined => {
  return value ? `0x${value.replace('0x', '')}` : undefined;
};

export const toAddressRouter = (value: string): Address => {
  return `0x${value.replace('0x', '')}`;
};

export const UNKNOWN_ICON = unknownIcon;
//@ts-ignore
export const CHAINID = window.chainID || 43113;

export const PERMIT_TOKENS = (chainId: number | undefined) => {
  if (chainId === 43113) {
    return ['0x5D60473C5Cb323032d6fdFf42380B50E2AE4d245'];
  } else {
    return [
      '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
      '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
      '0x532E6537FEA298397212F09A61e03311686f548e',
      '0x46A51127C3ce23fb7AB1DE06226147F446e4a857',
    ];
  }
};

export const TOKEN_ADDRESS = {
  43113: {
    USDC: '0x5D60473C5Cb323032d6fdFf42380B50E2AE4d245',
    USDT: '0x6e9FDaE1Fe20b0A5a605C879Ae14030a0aE99cF9',
    WAVAX: WETH9[43113].address,
    AVAX: AddressZero,
  },
  43114: {
    USDC: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    USDT: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
    WAVAX: WETH9[43114].address,
    AVAX: AddressZero,
  },
};

export const TOKEN_DECIMALS = {
  43113: {
    USDC: 6,
    USDT: 6,
    WAVAX: 18,
    AVAX: 18,
  },
  43114: {
    USDC: 6,
    USDT: 6,
    WAVAX: 18,
    AVAX: 18,
  },
};
export const DEFAULT_TOKENS: Token[] = [
  {
    //@ts-ignore
    address: TOKEN_ADDRESS[CHAINID].USDC,
    chainId: CHAINID,
    //@ts-ignore
    decimals: TOKEN_DECIMALS[CHAINID].USDC,
    logoURI: usdcIcon,
    name: 'USDC',
    symbol: 'USDC',
  },
  {
    //@ts-ignore
    address: TOKEN_ADDRESS[CHAINID].USDT,
    chainId: CHAINID,
    //@ts-ignore
    decimals: TOKEN_DECIMALS[CHAINID].USDT,
    logoURI: usdtIcon,
    name: 'USDT',
    symbol: 'USDT',
  },
  {
    //@ts-ignore
    address: TOKEN_ADDRESS[CHAINID].WAVAX,
    chainId: CHAINID,
    //@ts-ignore
    decimals: TOKEN_DECIMALS[CHAINID].WAVAX,
    logoURI: wavaxIcon,
    name: 'Wrapped Avalanche',
    symbol: 'WAVAX',
  },
  {
    //@ts-ignore
    address: TOKEN_ADDRESS[CHAINID].AVAX,
    chainId: CHAINID,
    //@ts-ignore
    decimals: TOKEN_DECIMALS[CHAINID].AVAX,
    logoURI: avaxIcon,
    name: 'Avalanche',
    symbol: 'AVAX',
  },
];
export const APPROVE_AMOUNT = BigNumber.from(
  '115792089237316195423570985008687907853269984665640564039457584007913129639935'
);
export const SLIPPAGE = new Percent(1, 100); // 1%
//@ts-ignore
export const ROUTER_ADDRESS = chain => {
  if (chain === 43114) {
    //@ts-ignore
    return toAddressRouter(RESERV_ROUTER_ADDRESS[chain]);
  } else {
    return toAddressRouter(RESERV_ROUTER_ADDRESS[43113]);
  }
};
