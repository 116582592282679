import React, { useState } from 'react';
import style from './AnalyticsItem.module.scss';
import styled from 'styled-components';
import { usePopper } from 'react-popper';
import { useNavigate } from 'react-router-dom';
import { TokenIcon } from '../../TokenIcon';
// import plus from '../../../assets/plus.svg';
import { formatPercentage } from '../../../utils';

function abbreviateNumber(value: number) {
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(value);
}

const Tooltip = styled.div`
  border-radius: 8px;
  padding: 10px;
  width: max-content;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 0.8rem;
  min-width: 240px;
  visibility: hidden;
  color: #f8e0bb;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;

  &[data-show='true'] {
    visibility: visible;
    opacity: 1;
  }
`;

export const AnalyticsItem: React.FC<any> = ({
  item,
  tvl,
  volume,
  swapApr,
  managedApy,
  apr,
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const navigate = useNavigate();

  const goTo = (path: string) => {
    navigate(path, {});
  };
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right',
    strategy: 'fixed',
  });

  const showTooltip = () => {
    // @ts-ignore
    popperElement.setAttribute('data-show', true);
  };

  const hideTooltip = () => {
    // @ts-ignore
    popperElement.removeAttribute('data-show');
  };

  return (
    <>
      {/* @ts-ignore */}
      <tr
        className={style.analyticsItem}
        //@ts-ignore
        ref={setReferenceElement}
        onMouseLeave={() => hideTooltip()}
      >
        <td
          className={style.symbol}
          style={{ cursor: 'pointer' }}
          onClick={() =>
            goTo(
              `/add_liquidity?tokenA=${item?.token0}&tokenB=${item?.token1}&curveId=${item?.curveId}`
            )
          }
        >
          <TokenIcon tokenName={item?.token0Symbol} />
          <TokenIcon tokenName={item?.token1Symbol} />
          <div>
            {item?.token0Symbol} / {item?.token1Symbol}
            <div className="poolType">
              {item?.curveId === 0 ? 'classic' : 'stable'}
            </div>
          </div>
        </td>
        <td>{abbreviateNumber(tvl)}</td>
        <td>{volume.toFixed(2)}</td>
        {/* <td>
          288.6k $
        </td>
        <td>
          682.1k $
        </td>
        <td>
          1.2k $
        </td>
        <td>
          8.6k $
        </td> */}
        <td onMouseEnter={showTooltip} onClick={showTooltip}>
          {formatPercentage(apr)}%
        </td>
        <Tooltip
          /* @ts-ignore */
          ref={setPopperElement}
          onMouseEnter={showTooltip}
          style={styles.popper}
          {...attributes.popper}
        >
          <div className="wrapperPopper">
            <div className={style.poperHeader}>
              <div style={{ position: 'relative' }}>
                <span>{formatPercentage(swapApr)}%</span> Swap fee APR
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  className="plusIcon"
                >
                  <path
                    d="M13 5.41667H7.58333V0H5.41667V5.41667H0V7.58333H5.41667V13H7.58333V7.58333H13V5.41667Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div>
                <span>{formatPercentage(managedApy)}%</span> AAVE APR
              </div>
            </div>
            <div className={style.poperBody}>
              <div>Total APR</div>
              <div>
                <span>{formatPercentage(apr)}%</span>
              </div>
            </div>
          </div>
        </Tooltip>
      </tr>
    </>
  );
};
