import React, { useEffect } from 'react';
import { Header } from './components/Header';
import './App.scss';
import {
  WagmiConfig,
  RainbowKitProvider,
  wagmiClient,
  chains,
} from './rainbow';
import { Footer } from './components/Footer';
import { RoutesList } from './routes';
import { useNavigate } from 'react-router-dom';
import { Routes } from './enums';

import { Buffer } from 'buffer';

// @ts-ignore
window.Buffer = Buffer;

const App: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    //@ts-ignore
    if (!Object.values(Routes).includes(window.location.pathname)) {
      navigate(Routes.swap);
    }
  }, [window.location.pathname]);

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <div className="app">
          <Header />
          <main className="app__page page">
            <div className="page__container">
              <RoutesList />
            </div>
          </main>
          <Footer />
        </div>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default App;
