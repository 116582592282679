import React, { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getTokenPrice } from '../../redux/actions/price';

interface TokenIconProps {
  tokenName: string;
  overlap?: boolean;
}

export const TokenIcon: React.FC<TokenIconProps> = ({
  tokenName,
  overlap = false,
}) => {
  const [icon, setIcon] = useState('');
  const token = useSelector(
    (state: RootState) => state.priceReducer[tokenName.replace('.e', '')]
  );
  const dispatch: AppDispatch = useDispatch();

  const getPrice = async () => {
    await dispatch(getTokenPrice(tokenName.replace('.e', '')));
  };

  useEffect(() => {
    getPrice();
  }, [tokenName]);

  useEffect(() => {
    if (token) {
      setIcon(token.thumb);
    }
  }, [token]);

  return (
    <label>
      {icon ? (
        <img
          src={icon}
          style={{
            width: '16px',
            height: '16px',
            marginRight: '6px',
            borderRadius: '100%',
            position: 'relative',
            top: '3px',
            left: overlap ? '-10px' : 0,
          }}
        />
      ) : (
        ''
      )}
    </label>
  );
};
