import { store } from './redux/store';

import {
  loadTransactions,
  setCurrentTransaction,
} from './redux/actions/transactions';
import mitt from 'mitt';

const emitter = mitt();

emitter.on('addTransaction', payload => {
  const transactions = localStorage.getItem('transactions');

  if (transactions) {
    localStorage.setItem(
      'transactions',
      JSON.stringify([...JSON.parse(transactions), payload])
    );
  } else {
    localStorage.setItem('transactions', JSON.stringify([payload]));
  }

  store.dispatch(loadTransactions());
});

emitter.on('watchTransaction', hash => {
  store.dispatch(setCurrentTransaction(String(hash)));
});

export default emitter;
