import React from 'react';
import style from './Transaction.module.scss';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { TransactionPayload } from '../../../../interfaces';
import { TransactionType } from '../../../../enums';
import { TokenIcon } from '../../../TokenIcon';

interface TransactionProps {
  transaction: TransactionPayload;
}

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

const parseTransaction = (transaction: TransactionPayload) => {
  switch (transaction.type) {
    case TransactionType.swap:
      return `${transaction.status} swapped ${transaction.inputAmount} ${transaction.inputToken} for ${transaction.outputAmount} ${transaction.outputToken}`;
    case TransactionType.add_liquidity:
      return `Added liq `;
    case TransactionType.remove_liquidity:
      return `Removed liq`;
  }
};

export const Transaction: React.FC<TransactionProps> = ({ transaction }) => {
  return (
    <>
      {transaction.type === TransactionType.swap ? (
        <tr>
          <td className="txCoinsWrapper">
            <div className="txCoins">
              <div className="fromTokenWrapper">
                <TokenIcon
                  tokenName={
                    transaction.inputToken || transaction.tokenA || 'avax'
                  }
                />
                <span>
                  {parseFloat(Number(transaction.inputAmount).toFixed(6))}{' '}
                  {transaction.inputToken}
                </span>
              </div>
              <div>
                <TokenIcon
                  tokenName={
                    transaction.outputToken || transaction.tokenB || 'avax'
                  }
                />
                <span>
                  {parseFloat(Number(transaction.outputAmount).toFixed(6))}{' '}
                  {transaction.outputToken}
                </span>
              </div>
            </div>
          </td>
          <td className="statusWrapper">
            <div className="greenDot"></div>
            {transaction.status}
          </td>
          <td className={style.transaction__timestamp}>
            {timeAgo.format(transaction.timestamp)}
          </td>
        </tr>
      ) : (
        <tr className={style.transaction}>
          <td className="txCoinsWrapper">{parseTransaction(transaction)}</td>
          <td className="statusWrapper">
            <div className="greenDot"></div>
            {transaction.status}
          </td>
          <td className={style.transaction__timestamp}>
            {timeAgo.format(transaction.timestamp)}
          </td>
        </tr>
      )}
    </>
  );
};
