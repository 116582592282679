import React, { useState } from 'react';
import classNames from 'classnames';
import style from './Liquidity.module.scss';
import { LiquidityAction, LiquidityType } from '../../enums';
import { AddLiquidity } from './AddLiquidity';
import { RemoveLiquidity } from './RemoveLiquidity';

interface LiquidityProps {
  liquidityAction: LiquidityAction;
}

export const Liquidity: React.FC<LiquidityProps> = ({ liquidityAction }) => {
  const [isTypeSelected] = useState<boolean>(true);
  const [liquidityType, setLiquidityType] = useState<LiquidityType>(
    LiquidityType.classic
  );

  const handleLiquidityType = (type: LiquidityType) => {
    setLiquidityType(type);
  };

  const handleTriggerChangeCurveId = (curveId: any) => {
    if (curveId == 0) {
      handleLiquidityType(LiquidityType.classic);
    }
    if (curveId == 1) {
      handleLiquidityType(LiquidityType.stable);
    }
  };

  return (
    <div className={style.liquidity__container}>
      {liquidityAction === LiquidityAction.add ? (
        <>
          {/* <div className={style.liquidity__network_wrapper}>
            <div
              className={style.network__tooltip_wrapper}
              style={
                showNetworkTooltip
                  ? { visibility: 'visible' }
                  : { visibility: 'hidden' }
              }
            >
              <p className={style.network__tooltip_text}>
                Other chains coming soon
              </p>
            </div>
            <div
              className={style.liquidity__network}
              onMouseEnter={() => setShowNetworkTooltip(!showNetworkTooltip)}
              onMouseLeave={() => setShowNetworkTooltip(!showNetworkTooltip)}
            >
              <p className={style.liquidity__network_title}>
                1. Select Network
              </p>
              <span className={style.liquidity__network_logo}>
                <img
                  src={avalancheIcon}
                  alt="network icon"
                  className={style.liquidity__network_img}
                />
              </span>
            </div>
          </div> */}
          <div className={style.liquidity__type}>
            <div className={style.liquidity__type_info}>
              <p className={style.liquidity__type_title}>Select Type</p>

              <button type="button" className={style.liquidity__type_chosen}>
                {liquidityType === 0 ? 'Classic' : 'Stable'}
              </button>
            </div>

            {isTypeSelected && (
              <div
                className={classNames(style.liquidity__type_options, {
                  [style.type__options_selected]: liquidityType,
                })}
                style={
                  isTypeSelected
                    ? { visibility: 'visible' }
                    : { display: 'none' }
                }
              >
                <div
                  className={classNames(style.liquidity__type_classic, {
                    [style.type__selected]:
                      liquidityType === LiquidityType.classic,
                  })}
                  onClick={() => handleLiquidityType(LiquidityType.classic)}
                >
                  <p className={style.type__title}>Classic</p>
                  <p className={style.type__details}>
                    Suitable for regular pairs
                  </p>
                </div>
                <div
                  className={classNames(style.liquidity__type_stable, {
                    [style.type__selected]:
                      liquidityType === LiquidityType.stable,
                  })}
                  onClick={() => handleLiquidityType(LiquidityType.stable)}
                >
                  <p className={style.type__title}>Stable</p>
                  <p className={style.type__details}>
                    Suitable for stable pairs
                  </p>
                </div>
              </div>
            )}
          </div>
          <AddLiquidity
            liquidityType={liquidityType}
            handleTriggerChangeCurveId={handleTriggerChangeCurveId}
          />
        </>
      ) : (
        <RemoveLiquidity />
      )}
    </div>
  );
};
