import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Wallet } from '../components/Wallet';
import { Liquidity } from '../components/Liquidity';
import { Analytics } from '../components/Analytics';

import { LiquidityAction, Routes as Direction } from '../enums';

export const RoutesList: React.FC = () => (
  <Routes>
    <Route path={Direction.swap} element={<Wallet />} />
    <Route
      path={Direction.addLiquidity}
      element={<Liquidity liquidityAction={LiquidityAction.add} />}
    />
    <Route
      path={Direction.removeLiquidity}
      element={<Liquidity liquidityAction={LiquidityAction.remove} />}
    />
    <Route path={Direction.analytics} element={<Analytics />} />
  </Routes>
);
