import React, { useEffect } from 'react';
import { toAddress } from '../../constants';
import { AddressZero } from '@ethersproject/constants';
import { useBalance, useAccount } from 'wagmi';

interface BalanceProps {
  address: string;
  triggerBalanceValue?: (balance: any) => void;
}

export const Balance: React.FC<BalanceProps> = ({
  address,
  triggerBalanceValue,
}) => {
  const { address: userAddress } = useAccount();
  const { data } = useBalance({
    token: address === AddressZero ? undefined : toAddress(address),
    address: userAddress,
    watch: true,
  });

  useEffect(() => {
    if (data?.formatted && triggerBalanceValue) {
      triggerBalanceValue(parseFloat(Number(data.formatted).toFixed(6)));
    }
  }, [data]);

  return (
    <span>{data ? parseFloat(Number(data.formatted).toFixed(6)) : 0}</span>
  );
};
