import React, { useEffect, useState } from 'react';
import style from './LiquidityToken.module.scss';
import { Fetcher, Pair } from '@reservoir-labs/sdk';
import { toAddress } from '../../../../constants';
import { useProvider, useBalance, useAccount, useNetwork } from 'wagmi';
import classNames from 'classnames';
import { TokenIcon } from '../../../TokenIcon';

interface LiquidityTokenProps {
  address: string;
  activeToken: string;
  onClick: (data: any) => void;
}

export const LiquidityToken: React.FC<LiquidityTokenProps> = ({
  address,
  onClick,
  activeToken,
}) => {
  const [pairData, setPairData] = useState<Pair | null>(null);
  const [showLP, setShowLP] = useState(false);

  const provider = useProvider();
  const { address: userAddress } = useAccount();
  const { chain: chain_id } = useNetwork();

  const fetchPairData = async () => {
    if (!chain_id) return;

    const pairResponse = await Fetcher.fetchPairDataUsingAddress(
      chain_id?.id,
      address,
      provider
    );
    setPairData(pairResponse);
  };

  const { data: lpTokenBalance } = useBalance({
    token: toAddress(pairData?.liquidityToken.address) || undefined,
    address: userAddress,
    watch: true,
    enabled: pairData !== null,
  });

  useEffect(() => {
    fetchPairData();
  }, [address]);

  useEffect(() => {
    if (
      pairData?.liquidityToken.address &&
      Number(lpTokenBalance?.formatted) > 0
    ) {
      setShowLP(true);
    }
  }, [lpTokenBalance?.formatted, pairData]);

  return (
    <div
      className={`${classNames(style.liquidityToken, {
        [style.liquidityToken__active]: activeToken === address,
      })}`}
      style={{ display: showLP ? 'flex' : 'none' }}
      onClick={() => onClick(pairData)}
    >
      <div className={style.liquidityToken__wrapper}>
        <div className={style.liquidityToken__check}>
          <svg
            width="10"
            height="8"
            viewBox="0 0 10 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.92372 1.2123L3.49946 7.63656L0.0751953 4.2123L0.923723 3.36377L3.49946 5.93951L9.0752 0.36377L9.92372 1.2123Z"
              fill="#0F3846"
            />
          </svg>
        </div>
        {pairData && (
          <span>
            <TokenIcon tokenName={pairData.token0.symbol || 'avax'} />
            <TokenIcon overlap tokenName={pairData.token1.symbol || 'avax'} />
            {pairData?.token0.symbol}-{pairData?.token1.symbol}
          </span>
        )}
      </div>
      <span className="lpBalance">
        {pairData && Number(lpTokenBalance?.formatted).toFixed(8)}
      </span>
    </div>
  );
};
