import axios from 'axios';

export default class Base {
  getUrl(path: string) {
    return path;
  }

  errorCatch(error: any) {
    console.log(error);
    // TODO: might want to ignore the following errors:
    // HTTP429 for too many requests which is common for coingecko
    // HTTP404 for broken links of images from traderjoe's list
  }

  get(url: string, config?: any) {
    return axios
      .get(this.getUrl(url), config)
      .then(res => res.data)
      .catch(this.errorCatch);
  }

  post(url: string, data: any, config?: any) {
    return axios
      .post(this.getUrl(url), data, config)
      .then(res => res.data)
      .catch(error => ({ status: false, message: error }));
  }

  put(url: string, data: any, config?: any) {
    return axios
      .put(this.getUrl(url), data, config)
      .then(res => res.data)
      .catch(error => ({ status: false, message: error }));
  }

  delete(url: string, data: any, config?: any) {
    return axios
      .delete(this.getUrl(url), config)
      .then(res => res.data)
      .catch(error => ({ status: false, message: error }));
  }
}
