import React, { useCallback, useEffect, useState } from 'react';
import style from './WalletPanel.module.scss';
import cx from 'classnames';

import avatarIcon from '../../../assets/images/avatar.svg';
import arrowRightIcon from '../../../assets/images/arrowRight.svg';
import pendingIcon from '../../../assets/loader.gif';
import errorIcon from '../../../assets/images/error.svg';
import successIcon from '../../../assets/images/success.svg';

import { Transaction } from './Transaction';

import OutsideClickHandler from 'react-outside-click-handler';

import { useConnectModal } from '@rainbow-me/rainbowkit';
import {
  useAccount,
  useDisconnect,
  useBalance,
  useWaitForTransaction,
} from 'wagmi';
import { useSelector, useDispatch } from 'react-redux';
import { getAvaxPrice } from '../../../redux/actions/price';
import {
  loadTransactions,
  setCurrentTransaction,
} from '../../../redux/actions/transactions';
import type { RootState, AppDispatch } from '../../../redux/store';
import { Tab } from '../../../enums';
import { TransactionPayload } from '../../../interfaces';

export const WalletPanel: React.FC = () => {
  const [isPanelOpened, togglePanel] = useState(false);
  const [currentTab, changeCurrentTab] = useState<Tab>(Tab.default);
  const [usdPrice, setUsdPrice] = useState<string>('$0.00');
  const [copied, setCopied] = useState(false);

  const { openConnectModal } = useConnectModal();
  const { address, isDisconnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { data } = useBalance({
    address,
  });

  const avaxPrice = useSelector(
    (state: RootState) => state.priceReducer.avaxPrice
  );

  const transactions = useSelector(
    (state: RootState) => state.transactionsReducer.transactions
  );

  const currentTxHash = useSelector(
    (state: RootState) => state.transactionsReducer.current_transaction
  );

  const {
    data: txData,
    isError: txError,
    isLoading: txLoading,
  } = useWaitForTransaction({
    hash: currentTxHash,
  });

  const dispatch: AppDispatch = useDispatch();

  const clearTransactions = () => {
    localStorage.removeItem('transactions');
    dispatch(loadTransactions());
  };

  useEffect(() => {
    if (txData && txData.status === 1) {
      setTimeout(() => {
        dispatch(setCurrentTransaction(''));
      }, 5000);
    }
  }, [txData]);

  useEffect(() => {
    if (isPanelOpened === true) {
      dispatch(getAvaxPrice());
    }
  }, [isPanelOpened]);

  useEffect(() => {
    dispatch(loadTransactions());
  }, []);

  useEffect(() => {
    if (data?.formatted) {
      setUsdPrice(
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(avaxPrice * Number(data.formatted))
      );
    }
  }, [data?.formatted, avaxPrice]);

  const handlePanelClick = () => {
    if (isDisconnected && openConnectModal) {
      openConnectModal();
    } else {
      togglePanel(!isPanelOpened);
    }
  };

  const handleDisconnect = () => {
    togglePanel(false);
    disconnect();
  };

  const copyAddress = () => {
    if (address) {
      try {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
        navigator.clipboard.writeText(address);
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    }
  };

  const renderContent = useCallback(() => {
    switch (currentTab) {
      case Tab.default:
        return (
          <section className={style.walletPanel__panel}>
            <header>
              <div>
                <img src={avatarIcon} className={style.walletPanel__avatar} />

                <>
                  {address &&
                    `${address?.substring(0, 6)}...${address?.slice(-4)}`}
                </>
              </div>
              <div>
                <div
                  className={style.walletPanel__panel_icon}
                  onClick={() => copyAddress()}
                >
                  {copied == true ? (
                    'Copied!'
                  ) : (
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.73692 11.5H2.96849C2.49948 11.5 2.04967 11.3156 1.71803 10.9874C1.38639 10.6592 1.20007 10.2141 1.20007 9.75V2.75C1.20007 2.28587 1.38639 1.84075 1.71803 1.51256C2.04967 1.18437 2.49948 1 2.96849 1H10.0422C10.5112 1 10.961 1.18437 11.2926 1.51256C11.6243 1.84075 11.8106 2.28587 11.8106 2.75V4.5M6.50534 15H13.579C14.048 15 14.4978 14.8156 14.8295 14.4874C15.1611 14.1592 15.3474 13.7141 15.3474 13.25V6.25C15.3474 5.78587 15.1611 5.34075 14.8295 5.01256C14.4978 4.68437 14.048 4.5 13.579 4.5H6.50534C6.03632 4.5 5.58652 4.68437 5.25487 5.01256C4.92323 5.34075 4.73692 5.78587 4.73692 6.25V13.25C4.73692 13.7141 4.92323 14.1592 5.25487 14.4874C5.58652 14.8156 6.03632 15 6.50534 15Z"
                        stroke="#0F3846"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
                <div
                  className={style.walletPanel__panel_icon}
                  title="Disconnect"
                  onClick={handleDisconnect}
                >
                  <svg
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.856 10.3333L14.9999 7.22222M14.9999 7.22222L11.856 4.11111M14.9999 7.22222H3.9964M8.71219 10.3333V11.1111C8.71219 11.7299 8.46377 12.3234 8.02158 12.761C7.57939 13.1986 6.97965 13.4444 6.35429 13.4444H3.21043C2.58508 13.4444 1.98534 13.1986 1.54315 12.761C1.10096 12.3234 0.852539 11.7299 0.852539 11.1111V3.33333C0.852539 2.71449 1.10096 2.121 1.54315 1.68342C1.98534 1.24583 2.58508 1 3.21043 1H6.35429C6.97965 1 7.57939 1.24583 8.02158 1.68342C8.46377 2.121 8.71219 2.71449 8.71219 3.33333V4.11111"
                      stroke="#0F3846"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </header>
            <div className={style.walletPanel__balance}>
              <div className={style.walletPanel__balance_value}>
                {Number(data?.formatted).toFixed(3)} {data?.symbol}
              </div>
              <div className={style.walletPanel__balance_usd}>{usdPrice}</div>
            </div>
            <div
              className={style.walletPanel__tab}
              onClick={() => changeCurrentTab(Tab.transactions)}
            >
              Transactions
              <img src={arrowRightIcon} />
            </div>
          </section>
        );
      case Tab.transactions:
        return (
          <section className={cx(style.walletPanel__panel, style.transactions)}>
            <header>
              <img
                src={arrowRightIcon}
                style={{ transform: 'scale(-1, -1)', cursor: 'pointer' }}
                onClick={() => changeCurrentTab(Tab.default)}
              />
              <span>Transactions</span>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => clearTransactions()}
              >
                Clear All
              </span>
            </header>
            <div className={style.walletPanel__transactions}>
              <table>
                <thead>
                  <th>COINS</th>
                  <th>STATUS</th>
                  <th>TIME</th>
                </thead>
                <tbody>
                  {transactions.map(
                    (transaction: TransactionPayload) =>
                      transaction.creator === address && (
                        <Transaction
                          transaction={transaction}
                          key={transaction.timestamp}
                        />
                      )
                  )}
                </tbody>
              </table>
            </div>
          </section>
        );
    }
  }, [currentTab, usdPrice, transactions.length, copied]);

  return (
    <div className={style.walletPanel}>
      <span
        className={
          style[
            isPanelOpened ? 'walletPanel__buttonOpened' : 'walletPanel__button'
          ]
        }
        onClick={() => handlePanelClick()}
      >
        {isDisconnected ? (
          <>Connect Wallet</>
        ) : (
          <>
            {txLoading && (
              <span className={style.pending}>
                <img src={pendingIcon} className={style.walletPanel__avatar} />
                Pending...
              </span>
            )}
            {txError && (
              <span className={style.error}>
                <img src={errorIcon} className={style.walletPanel__avatar} />
                Error!
              </span>
            )}
            {txData !== undefined && (
              <span className={style.success}>
                <img src={successIcon} className={style.walletPanel__avatar} />
                Success!
              </span>
            )}
            {!txLoading && !txData && !txError && (
              <>
                <img src={avatarIcon} className={style.walletPanel__avatar} />
                {address &&
                  `${address?.substring(0, 6)}...${address?.slice(-4)}`}
              </>
            )}
          </>
        )}
      </span>
      <OutsideClickHandler
        onOutsideClick={() => {
          if (isPanelOpened) {
            togglePanel(false);
          }
        }}
      >
        {isPanelOpened && renderContent()}
      </OutsideClickHandler>
    </div>
  );
};
