import { IAction, PriceAppState } from '../../types/index';
import types from './types';

const initialState: PriceAppState = {
  avaxPrice: 0,
};

const priceReducer = (
  state = initialState,
  action: IAction<string | boolean | any>
) => {
  switch (action.type) {
    case types.UPDATE_AVAX_PRICE:
      return {
        ...state,
        avaxPrice: action?.data,
      };
    case types.GET_TOKEN_PRICE:
      return {
        ...state,
        [action.data.symbol]: {
          price: action?.data?.price,
          thumb: action?.data?.thumb,
          id: action?.data?.id,
          lastUpdate: action?.data?.lastUpdate,
        },
      };
    default:
      return state;
  }
};

export default priceReducer;
