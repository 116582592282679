import types from '../reducers/tokens/types';
import CoingeckoApi from '../../api/Coingecko';
import { DEFAULT_TOKENS } from '../../constants';
import avaxIcon from '../../assets/images/avax.png';

export const getTokens =
  () => (dispatch: (arg0: { type: string; data: any }) => void) => {
    return new CoingeckoApi()
      .getTokens()
      .then(response => {
        const tokens = response;
        // @ts-ignore
        // @ts-ignore
        const avaxToken = {
          address: '0x0000000000000000000000000000000000000000',
          chainId: 43114,
          decimals: 18,
          logoURI: avaxIcon,
          name: 'Avalanche',
          symbol: 'AVAX',
        };
        const dataArray =
          //@ts-ignore
          window.chainID === 43113
            ? [...DEFAULT_TOKENS]
            : [...tokens, avaxToken];

        dispatch({
          type: types.UPDATE_TOKENS_LIST,
          data: dataArray,
          // data: [...DEFAULT_TOKENS],
        });
        return tokens;
      })
      .catch((error: any) => {
        throw error;
      });
  };
