import { createRoot } from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Analytics } from "@vercel/analytics/react";

console.error = () => {};

const apolloClient = new ApolloClient({
  uri: "https://data.staging.arkiver.net/robolabs/reservoir-mainnet-v2/graphql",
  cache: new InMemoryCache(),
});

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Router>
    {/* <React.StrictMode> */}
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <App />
        <Analytics />
      </ApolloProvider>
    </Provider>
    {/* </React.StrictMode> */}
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
