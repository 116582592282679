import { IAction, TokensAppState } from '../../types/index';
import types from './types';

const initialState: TokensAppState = {
  tokens: [],
};

const priceReducer = (
  state = initialState,
  action: IAction<string | boolean>
) => {
  switch (action.type) {
    case types.UPDATE_TOKENS_LIST:
      return {
        ...state,
        tokens: action?.data,
      };
    default:
      return state;
  }
};

export default priceReducer;
