import React, { useEffect } from 'react';
import { useBalance, Address } from 'wagmi';
import { AddressZero } from '@ethersproject/constants';
import { AppDispatch } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { getTokenPrice } from '../../../redux/actions/price';

interface TokenBalanceProps {
  userAddress: Address | undefined;
  tokenAddress: Address;
  tokenName?: string;
  triggerMoreThenZero?: (contractAddress: any) => void;
}

export const TokenBalance: React.FC<TokenBalanceProps> = ({
  userAddress,
  tokenAddress,
  tokenName,
  triggerMoreThenZero,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const balance = useBalance({
    address: userAddress,
    token: tokenAddress === AddressZero ? undefined : tokenAddress,
    watch: true,
  });

  // const { data } = useBalance({
  //   token: tokenAddress === AddressZero ? undefined : toAddress(tokenAddress),
  //   chainId: chain?.id,
  //   address: userAddress,
  //   watch: true,
  //   enabled: tokenAddress != null && userAddress != null,
  // });

  const getPrice = async () => {
    if (tokenName) {
      await dispatch(getTokenPrice(tokenName));
    }
  };

  useEffect(() => {
    if (Number(balance?.data?.formatted) > 0 && tokenName) {
      getPrice();
    }
  }, [balance]);

  useEffect(() => {
    if (Number(balance?.data?.formatted) > 0 && triggerMoreThenZero) {
      triggerMoreThenZero(tokenAddress);
    }
  }, [balance]);

  return (
    <span>
      {balance.data ? parseFloat(Number(balance.data.formatted).toFixed(4)) : 0}
    </span>
  );
};
